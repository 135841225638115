import React from "react";

import Widget from "components/Widget/index";

class PhotosCard extends React.Component {
  render() {
  const { heading, title, subTitle, ibos } = this.props.data
  const { themeStyle } = this.props
    return (
      <Widget styleName={(themeStyle || 'gx-pink-purple-gradient') + " gx-text-white gx-card-1367-p"}>
        <h3 className="gx-font-weight-semi-bold gx-text-white">{heading || '0'} Prospect(s)</h3>
        <h4 className="gx-text-white">{Object.keys(ibos).length || '0'} Businesse(s)</h4>
        <h4 className="gx-text-white">{title || ''}</h4>
        {/* <p className="gx-text-uppercase gx-fs-sm gx-mb-0">{subTitle || 'Photos'}</p> */}
      </Widget>
    );
  }
};

export default PhotosCard;
