import React from "react";
import { Avatar, Button, Form, Input } from "antd";
import IntlMessages from "util/IntlMessages";
import AIOLogo from "../assets/images/aiomeet-logo-square.png"

const FormItem = Form.Item;

const LockScreen = (props) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <div className="gx-login-container">
      <div className="gx-login-content gx-text-center gx-pb-5">

        <div className="gx-login-header">
          <Avatar shape="circle" className="gx-size-120" src={AIOLogo} />
        </div>
        <div className="gx-mb-4">
          <h3>Welcome to AIO Meet</h3>
        </div>

        <a type="primary" href="https://app.aiomeet.com">
          <IntlMessages id="app.userAuth.login"></IntlMessages>
        </a>


      </div>
    </div>
  );
};

const WrappedLockScreenForm = Form.create()(LockScreen);

export default (WrappedLockScreenForm);
