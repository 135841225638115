import React from "react";
import {Row, Col, Button, Input} from "antd";
import Auxiliary from "util/Auxiliary";
import IntlMessages from "util/IntlMessages";
import {Link} from "react-router-dom";

export default class NewGroup extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      thumb: '',
      title: '',
      subTitle: '',
      members: []
    }
  }
  
  onSave() {
    alert(this.state.name)
  }
  onCancel() {
    alert('cancel')
  }

  render() {
    const {title, subTitle} = this.state;
    let {thumb} = this.state;
    if (!thumb) {
      thumb = require('assets/images/placeholder.jpg');
    }
    return (
      <Auxiliary>
        <h2>{title === '' ?
          <IntlMessages id="contact.addGroup"/> :
          <IntlMessages id="contact.saveGroup"/>}</h2>
        <div className="gx-ml-auto">
          {/* <Avatar size="large" src={thumb}/> */}
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-form-group">
                <Input
                  required
                  placeholder="First Name"
                  onChange={(event) => this.setState({title: event.target.value})}
                  defaultValue={title}
                  margin="none"/>
              </div>
            </Col >
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-form-group">
                <Input
                  required
                  placeholder="First Name"
                  onChange={(event) => this.setState({subTitle: event.target.value})}
                  defaultValue={subTitle}
                  margin="none"/>
              </div>
            </Col >
          </Row>
          
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Button >
                <Link className="h5" to="../groups">Cancel</Link>
              </Button>
              <Button type='primary' onClick={() => this.onSave()} >Save</Button>
            </Col>
          </Row>
        </div>
      </Auxiliary>
    )
  }
}

