import React from "react";
import { Col, Row, Tabs } from "antd";
import PhotosCard from "components/Widgets/PhotosCard";
import ProspectCard from "components/Widgets/ProspectCard";
import ToolTheDay from "components/Widgets/ToolTheDay"
import Auxiliary from "util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import { useSelector } from "react-redux";

const TabPane = Tabs.TabPane;

const RenderDashboard = (props) => {
  const { totalCandidate, filteredIbos, dashboard } = props
  let totalIboCount = 0
  const arList = ['Aruna & Thilaka', 'Kanaga & Selvi',
    'Kannan & Dhana', 'Genga & Thevaki', 'Siva & Rani',
    'Lanka & Jeyanthi', 'Simon Peter & Polly', 'Selva & Puspa', 'Seelan & Sujitha', 'Kanesh & Kiruba']
  const arIbos = []
  const arPlatinums = {}
  const arCandidates = []
  let arIboCount = 0
  for (let key in filteredIbos) {
    if (arList.indexOf(filteredIbos[key].platinum) != -1) {
      arPlatinums[filteredIbos[key].platinum] = true
      arIbos.push(filteredIbos[key])
      arCandidates.push(...filteredIbos[key].candidates)
    }
  }
  // console.log(arIbos, arPlatinums, arCandidates)
  const { platinums, platinumIbos } = dashboard
  if(platinumIbos.length) {
    for (let key in platinumIbos) {
      if (arList.indexOf(platinumIbos[key].platinum) == -1) {
        totalIboCount = totalIboCount + platinumIbos[key].candidates.length
      }
      if (arList.indexOf(platinumIbos[key].platinum) != -1) {
        arIboCount = arIboCount + platinumIbos[key].candidates.length
      } 
    }
  }
  
  // console.log('platinumIbos', platinumIbos)
  const loading = useSelector(({ common }) => common.loading);

  return (
    <Auxiliary>
      {loading && <div className="gx-loader-view gx-loader-position ant-modal-mask">
        <CircularProgress />
      </div>}
      <Tabs defaultActiveKey="1">
        <TabPane tab="Diva Team" key="1">
          <Row>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <ToolTheDay title="Prospects" btnText="Diva & Latha Team" number={totalCandidate - arCandidates.length} />
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <ToolTheDay title="IBO's Inviting" btnText="Diva & Latha Team" number={dashboard.ibos.length - arIbos.length} />
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <ToolTheDay title="Platinum & up" btnText="Diva & Latha Team" number={dashboard.platinums.length - Object.keys(arPlatinums).length} />
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <ToolTheDay title="Total IBO's" btnText="Diva & Latha Team" number={totalIboCount} />
            </Col>
          </Row>
          <h1>By Leaders</h1>
          <Row>
            {
              platinums.map(item =>
                {
                  if (arList.indexOf(item.title) == -1)
                    return <Col key={Math.random()} xl={4} lg={12} md={12} sm={12} xs={24}>
                        <PhotosCard key={Math.random(3)} data={item} themeStyle={(arList.indexOf(item.title) == -1) ? '' : 'gx-green-cyan-gradient'} /> 
                      </Col>
                }
              )
            }
          </Row>
          <Row className="gx-mb-2">
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <h1>Total IBO Inviting Prospects {filteredIbos.length - arIbos.length}</h1>
            </Col>
          </Row>
          <Row>
            {
              filteredIbos.map(item => {
                if (arList.indexOf(item.platinum) == -1)
                  return <Col key={Math.random(3)} xl={6} lg={12} md={12} sm={12} xs={24}>
                    <ProspectCard key={Math.random(3)} data={item} themeStyle={(arList.indexOf(item.platinum) == -1) ? 'gx-blue-cyan-gradient' : 'gx-green-cyan-gradient'} />
                  </Col>
                }
              )
            }
          </Row>
          <Row className="gx-mb-2">
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <h1>{totalIboCount} IBO's attending from {platinumIbos.filter(i => arList.indexOf(i.platinum) == -1 ).length} Platinumship</h1>
            </Col>
          </Row>
          <Row>
            {
              platinumIbos.map(item => {
                if (arList.indexOf(item.platinum) == -1) {
                  return <Col key={Math.random(3)} xl={6} lg={12} md={12} sm={12} xs={24}>
                    <ProspectCard key={Math.random(3)} data={item} themeStyle={'gx-pink-purple-gradient'} />
                  </Col>
                }
                }
              )
            }
          </Row>
        </TabPane>
        <TabPane tab="Aruna Team" key="2">
        <Row>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <ToolTheDay title="Prospects" btnText="Aruna & Thilaka Team" number={arCandidates.length} />
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <ToolTheDay title="IBO's Inviting" btnText="Aruna & Thilaka Team" number={arIbos.length} />
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <ToolTheDay title="Platinum & up" btnText="Aruna & Thilaka Team" number={Object.keys(arPlatinums).length} />
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <ToolTheDay title="Total IBO's" btnText="Aruna & Thilaka Team" number={arIboCount} />
            </Col>
          </Row>
          <h1>By Leaders</h1>
          <Row>
            {
              platinums.map(item =>
                {
                  if (arList.indexOf(item.title) != -1)
                    return <Col key={Math.random()} xl={4} lg={12} md={12} sm={12} xs={24}>
                        <PhotosCard key={Math.random(3)} data={item}  /> 
                      </Col>
                }
              )
            }
          </Row>
          <Row className="gx-mb-2">
            <Col xl={4} lg={12} md={12} sm={12} xs={24}>
              <h1>Total IBO Count {arIbos.length}</h1>
            </Col>
          </Row>
          <Row>
            {
              filteredIbos.map(item => {
                if (arList.indexOf(item.platinum) != -1)
                  return <Col key={Math.random(3)} xl={6} lg={12} md={12} sm={12} xs={24}>
                    <ProspectCard key={Math.random(3)} data={item}  />
                  </Col>
                }
              )
            }
          </Row>
          <Row className="gx-mb-2">
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <h1>Total IBO's {arIboCount} attending from {platinumIbos.filter(i => arList.indexOf(i.platinum) != -1 ).length} Platinumship</h1>
            </Col>
          </Row>
          <Row>
            {
              platinumIbos.map(item => {
                if (arList.indexOf(item.platinum) != -1)
                  return <Col key={Math.random(3)} xl={6} lg={12} md={12} sm={12} xs={24}>
                    <ProspectCard key={Math.random(3)} data={item} themeStyle={'gx-pink-purple-gradient'} />
                  </Col>
                }
              )
            }
          </Row>
        </TabPane>
      </Tabs>

    </Auxiliary>
  )
}

export default RenderDashboard
