import React from "react";
import {connect} from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Row, Col, Form, Input, Button, Card, Modal, DatePicker, Badge, Switch } from "antd";
import { onGetAllUserMeetings } from "appRedux/actions/zoom/Meetings";
import {onGetUserProfile} from "appRedux/actions/user/Profile";

import eventsList from "./events";
const FormItem = Form.Item;
const localizer = momentLocalizer(moment);
class MeetCalendar extends React.Component {
  state = {
    ModalTitle: 'Schedule your Instazoom',
    visible: false,
    confirmLoading: false,
    start: '',
    end: '',
    slots: [],
    dateFormat: 'YYYY-MM-DD',
    timeFormat: 'HH:mm:ss',
    rate: 0.25,
    estimate: 0,
    events: []
  };
  
  async componentDidMount() {
    const {meetings, defaultSetting, profile, onGetUserProfile, onGetAllUserMeetings, authUser} = this.props
    if(!profile.firstName) await onGetUserProfile(authUser)
    if(meetings.meetings.length === 0 && defaultSetting && defaultSetting.userId) await onGetAllUserMeetings(defaultSetting.userId)
  }

  showModal = (slotInfo) => {
    const { start, end, slots } = slotInfo
    this.setState({
      start,
      end,
      slots,
      visible: true,
      estimate: (slots.length - 1) * this.state.rate
    })
  };
  handleOk = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const events = this.state.events
        events.push({
          title: `${values.name} - cost $${this.state.estimate}`,
          start: this.state.start,
          end: this.state.end
        })
        this.setState({events})
        console.log(values)
        // Object.keys(values).map(k => {if (!values[k]) delete values[k]})
        // this.setState({saving: true})
        // this.props.addMeet(values, this.resetValues.bind(this))
      }
    });
    this.setState({
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };
  handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({
      visible: false,
    });
  };
  onChange(checked, opt) {
    console.log(`switch to ${checked} ${opt}`);
  }

  getMeetings(meetings) {
    const events = []
    for(let key in meetings) {
      if(meetings[key]['start_time']) {
        const startTime = meetings[key]['start_time']
        const startUTC = new Date(startTime).getTime()
        const endUTC = startUTC + (meetings[key].duration * 60000)
        const start = new Date(startTime)
        const end = new Date(endUTC)
        const newMeet = {
          title: meetings[key].topic,
          start,
          end,
        }
        events.push(newMeet)
      }
    }
    return events
  }
  
  render() {
    const { getFieldDecorator } = this.props.form;
    const {meetings} = this.props
    const { visible, confirmLoading, ModalTitle, start, end, estimate, events, profile } = this.state;
    return (
      <div className="gx-main-content">
        <div className="gx-rbc-calendar">
          <Modal
            title={[
              <span key='title'>{ModalTitle}</span>,
              <Badge key='badge' className="gx-ml-2" count={`$ ${estimate}`} style={{backgroundColor: '#52c41a'}}/>
            ]}
            visible={visible}
            onOk={this.handleOk}
            confirmLoading={confirmLoading}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
              <Button key="submit" type="primary" loading={confirmLoading} onClick={this.handleOk}>
                Let's Meet
              </Button>,
            ]}
          >
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Form
                  layout="vertical" className="">
                  <Row>
                    <FormItem label="Instazoom Topic">
                      {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Please instazoom topic!', max: 50 }],
                      })(
                        <Input placeholder="Instazoom Topic" />
                      )}
                    </FormItem>
                  </Row>
                  <Row>
                    <FormItem label="Instazoom Password">
                      {getFieldDecorator('password', {
                        rules: [{ required: false, message: 'Please instazoom password!', max: 10 }],
                      })(
                        <Input placeholder="Instazoom Password" />
                      )}
                    </FormItem>
                  </Row>
                  <Row>
                    <FormItem label="Start at">
                    <DatePicker
                        className="gx-w-100"
                        format="YYYY-MM-DD HH:mm:ss"
                        value={moment(start)}
                        disabled
                      />
                    </FormItem>
                  </Row>
                  <Row>
                    <FormItem label="Finish at">
                      <DatePicker
                        className="gx-w-100"
                        format="YYYY-MM-DD HH:mm:ss"
                        value={moment(end)}
                        disabled
                      />
                    </FormItem>
                  </Row>
                  <Row>
                    <FormItem label="Host Video">
                      <Switch onChange={(e) => this.onChange(e, 'hostVideo')}/>
                    </FormItem>
                  </Row>
                  <Row>
                    <FormItem label="Participants Video">
                      <Switch defaultChecked onChange={(e) => this.onChange(e, 'partiVideo')}/>
                    </FormItem>
                  </Row>
                  <Row>
                    <FormItem label="Mute Upon Entry">
                      <Switch defaultChecked onChange={(e) => this.onChange(e, 'mute')}/>
                    </FormItem>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Modal>
          <Calendar
            localizer={localizer}
            selectable
            events={this.getMeetings(meetings.meetings)}
            defaultView='week'
            scrollToTime={new Date(2020, 2, 1, 6)}
            defaultDate={new Date()}
            onSelectEvent={event => alert(event.title)}
            onSelectSlot={(slotInfo) => this.showModal(slotInfo)}
            onSelectSlot={(slotInfo) => this.showModal(slotInfo)}
          />
        </div>
      </div>
    )
  };
}

const WrappedCalendar = Form.create()(MeetCalendar);

function mapStateToProps({meetings, user, common, auth}) {
  const {loading} = common
  const {profile, defaultSetting} = user
  const {authUser} = auth
  return {meetings, profile, defaultSetting, loading, authUser};
}

export default connect(
  mapStateToProps,
  {onGetAllUserMeetings, onGetUserProfile})(WrappedCalendar);
