import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Select, Button } from "antd";
import Auxiliary from "util/Auxiliary";
import RenderCards from "./RenderCard"
import CircularProgress from "components/CircularProgress/index";
import {
  onGetAllUserRegistrants,
  updateRegistrants,
  filterRegistrants,
  dataFeched
} from "appRedux/actions/zoom/Meetings";

const Option = Select.Option;

const Attendee = (props) => {
  const meetingId = props.match.params.id
  const dispatch = useDispatch();
  const loading = useSelector(({common}) => common.loading);
  let { registrants, renderRegistrants, platinums, ibos, callMade } = useSelector(({ meetings }) => meetings);
  if(registrants && registrants.length) {
    if(renderRegistrants && !renderRegistrants.length) {
      dispatch(updateRegistrants(registrants))
    }
    filterByIbo(registrants)
  }
  if (registrants && registrants.length === 0 && !callMade) {
    // getParticipants(meetingId)
  }

  function getParticipants(id) {
    dispatch(onGetAllUserRegistrants(id))
    dispatch(dataFeched())
    setTimeout(()=> {
      getParticipants(meetingId)
      dispatch(dataFeched())
    }, 600000)
  }

  const handleChange = (filterBy) => {
    if(registrants.length) {
      if(!filterBy || filterBy === 'By all Platinus') {
        renderRegistrants = registrants
      } else {
        dispatch(filterRegistrants(registrants, 'upline', filterBy))
      }
    }
  }

  const handleIboChange = (filterBy) => {
    if(registrants.length) {
      if(!filterBy || filterBy === 'All Candidates') {
        renderRegistrants = registrants
      } else {
        dispatch(filterRegistrants(registrants, 'ibo',filterBy))
      }
    }
  }

  function filterByIbo(registrants) {
    const byLeaders = {}
    const byIbo = {}
    for (let i = 0; i<registrants.length; i++) {
      const {first_name, last_name, email, custom_questions} = registrants[i]
      if(custom_questions[0] && custom_questions[0].value) {
        if(byLeaders[custom_questions[0].value]) {
          byLeaders[custom_questions[0].value].push({first_name, last_name, email})
        } else {
          byLeaders[custom_questions[0].value] = [
            {first_name, last_name, email}
          ]
        }
      }
      if(custom_questions[2] && custom_questions[2].value) {
        if(byIbo[custom_questions[2].value]) {
          byIbo[custom_questions[2].value].push({first_name, last_name, email})
        } else {
          byIbo[custom_questions[2].value] = [
            {first_name, last_name, email}
          ]
        }
      }
    }
  }

  const iboOptions = () => {
    const _iboOptions = []
    for (let i = 0; i < ibos.length; i++) {
      const value = ibos[i]
      _iboOptions.push(
        <Option key={value} value={value}>{value}</Option>
      )
    }
    return _iboOptions
  }

  const platinumOptions = () => {
    const _platinumOptions = []
    for (let i = 0; i < platinums.length; i++) {
      const value = platinums[i]
      _platinumOptions.push(
        <Option key={value} value={value}>{value}</Option>
      )
    }
    return _platinumOptions
  }
  
  return (
    <Auxiliary>
      {loading && <div className="gx-loader-view gx-loader-position ant-modal-mask">
        <CircularProgress/>
      </div>}
      <h2>Total Candidates: {renderRegistrants.length}</h2>
      <div className="gx-ml-auto">
        <Select className="gx-mb-2 gx-select-sm" defaultValue="By all Platinus" onChange={(val) => handleChange(val)}>
          {platinumOptions()}
        </Select>
        <Select className="gx-mb-2 gx-select-sm gx-ml-3" defaultValue="By all Ibos" onChange={(val) => handleIboChange(val)}>
          {iboOptions()}
        </Select>
        <Button type="primary" className="gx-ml-3 gx-mb-1" size="small" onClick={() => getParticipants(meetingId)}>
          <i className="icon icon-anchor gx-mr-2" /> Refresh Data
        </Button>
      </div>
      <Row className="gx-mt-3">
        <RenderCards registrants={renderRegistrants} meetingId={meetingId} />
      </Row>
    </Auxiliary>
  )
}


Attendee.propTypes = {};
export default Attendee;
