import axios from 'axios';
import publicIp from 'react-public-ip'

const getPublicIp = async () => {
    const ipv4 = await publicIp.v4() || "";
    const ipv6 = await publicIp.v6() || "";
    const now = new Date()
    const date = now.toISOString()
    const timestamp = now.getTime()
    return {ipv4, ipv6, date, timestamp}
}

const getIpMetadata = async () => {
    const ip = await getPublicIp()
    const url = `https://api.ipstack.com/${ip.ipv4}?access_key=`
    try {
        return await axios.get(url)
    } catch (error) {
        return Promise.reject({ message: "failed to get the registrants" })
    }
  }

export default { getPublicIp, getIpMetadata }
