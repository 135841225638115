import React from "react";
import {Row, Col} from "antd";
import Auxiliary from "util/Auxiliary";
import IntlMessages from "util/IntlMessages";
import BuildingCard from "components/Widgets/BuildingCard";
import {Link} from "react-router-dom";

export default class Groups extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      title: '',
      subTitle: ''
    }
  }
  
  onSave() {
    alert(this.state.name)
  }
  onCancel() {
    alert('cancel')
  }

  render() {
    const {title, subTitle} = this.state;
    let {thumb} = this.state;
    if (!thumb) {
      thumb = require('assets/images/placeholder.jpg');
    }
    return (
      <Auxiliary>
        <h2>
            <IntlMessages id="contact.groups"/>
            <Link className="gx-ml-3 h5" to="./groups/new">
                <i className="icon icon-add"/><span className="gx-pb-3">  Add</span>
            </Link>
        </h2>
        <div className="gx-ml-auto">
          <Row>
            <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                <BuildingCard title={title} sunTitle={subTitle} link="./groups/view"/>
            </Col >
          </Row>
        </div>
      </Auxiliary>
    )
  }
}

