import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchUserContactsSuccess, addUserContactSuccess, deleteUserContactSuccess } from '../actions/Contact'
import { db, auth, FieldValue } from '../../firebase/firebase';
import { ADD_USER_CONTACTS, GET_USER_CONTACTS, DELETE_USER_CONTACTS } from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import hash from "../../util/createHash"
const authUser = localStorage.getItem('user_id')

const getUserContacts = async (uid) => {
  if (uid) {
    const userRef = db.collection('contacts').doc(uid).collection('list').doc('contacts')
    return userRef.get().then(function (doc) {
      if (doc.exists) {
        return doc.data()
      } else {
        console.log("No such document!");
      }
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }
}

const addUserContact = async (contact, uid, cb) => {
  if (uid && contact && contact.email) {
    const id = contact.id
    const userRef = db.collection('contacts').doc(uid).collection('list').doc('contacts')
    userRef.set({
      [id]: contact
    },{merge: true}).then(() =>  {
      cb()
    }).catch(function (error) {
      console.log("Error getting document:", error);
      cb()
    });
    return contact
  }
}

const deleteUserContact = async (contact, uid) => {
  if (uid && contact && contact.id) {
    const userRef = db.collection('contacts').doc(uid).collection('list').doc('contacts')
    userRef.update({
      [contact.id]: FieldValue.delete()
    }).then().catch(function (error) {
      console.log("Error getting document:", error);
    });
    return contact
  }
}

function* deleteUserContactOnSuccess(payload) {
  const { contact } = payload
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const deletedUserContact = yield call(deleteUserContact, contact, uid);
    yield put(deleteUserContactSuccess(deletedUserContact));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* addUserContactOnSuccess(payload) {
  const { contact, cb } = payload
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const addedUserContact = yield call(addUserContact, contact, uid, cb);
    yield put(addUserContactSuccess(addedUserContact));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserContactsOnSuccess() {
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const fetchedUserContacts = yield call(getUserContacts, uid);
    yield put(fetchUserContactsSuccess(fetchedUserContacts));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserContacts() {
  yield takeEvery(GET_USER_CONTACTS, fetchUserContactsOnSuccess)
}

export function* addContact() {
  yield takeEvery(ADD_USER_CONTACTS, addUserContactOnSuccess);
}

export function* deleteContact() {
  yield takeEvery(DELETE_USER_CONTACTS, deleteUserContactOnSuccess);
}

export default function* rootSaga() {
  yield all([fork(addContact), fork(fetchUserContacts), fork(deleteContact)]);
}
