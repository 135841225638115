import React from "react";
import {Link} from "react-router-dom";
import {Row, Col, Button, Avatar, Input, Form} from "antd";
import Auxiliary from "util/Auxiliary";
import IntlMessages from "util/IntlMessages";
const FormItem = Form.Item;
class NewContact extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      saving: false
    }
  }
  
  onSave() {
    alert(this.state.firstName + ' ' + this.state.lastName)
  }
  resetValues() {
    this.props.form.resetFields()
    this.setState({saving: false})
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        Object.keys(values).map(k => {if (!values[k]) delete values[k]})
        this.setState({saving: true})
        this.props.addContact(values, this.resetValues.bind(this))
      }
    });
  }
  onCancel() {
    alert('cancel')
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showContactForm} = this.props
    let {saving} = this.state;
    return (
      <Auxiliary>
        <h2><IntlMessages id="contact.addContact"/></h2>
        <div className="gx-ml-auto">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form onSubmit={this.handleSubmit.bind(this)} layout="inline" className="gx-form-inline-label-up">
              <FormItem className="gx-form-item-two-fourth">
                {getFieldDecorator('firstName', {
                  rules: [{required: true, message: 'Please input First Name!', max: 25}],
                })(
                  <Input placeholder="First Name"/>
                )}
              </FormItem>
              <FormItem className="gx-form-item-two-fourth">
                {getFieldDecorator('lastName', {
                  rules: [{required: true, message: 'Please input Last Name!', max: 25}],
                })(
                  <Input placeholder="Last Name"/>
                )}
              </FormItem>
              <FormItem className="gx-form-item-two-fourth">
                {getFieldDecorator('email', {
                  rules: [{required: true, message: 'Please input Email!', type: 'email', max: 50}],
                })(
                  <Input placeholder="Email"/>
                )}
              </FormItem>
              <FormItem className="gx-form-item-two-fourth">
                {getFieldDecorator('phone', {
                  rules: [{required: false, message: 'Please input Phone Number!', len: 10}],
                })(
                  <Input placeholder="Phone" />
                )}
              </FormItem>
              <FormItem>
                <Button 
                disabled={(saving) ? "disabled" : ""}
                type="primary" className="gx-mb-0" htmlType="submit">
                  <span>Add Contact</span>
                </Button>
                <Button type="secondary" className="gx-mb-0" onClick={showContactForm} >
                  <span>Cancel</span>
                </Button>
              </FormItem>
            </Form>
            </Col>
          </Row>
        </div>
      </Auxiliary>
    )
  }
}

const WrappedNewContact = Form.create()(NewContact);

export default WrappedNewContact;
