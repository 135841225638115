import React from "react";

import Widget from "components/Widget/index";
import { Badge, Tooltip } from "antd";

class ProspectCard extends React.Component {
  render() {
  const { heading, headTitle, title, candidates } = this.props.data
  const { themeStyle } = this.props
    return (
      <Widget styleName={(themeStyle || 'gx-blue-cyan-gradient-reverse') + " gx-text-white gx-card-1367-p"}>
        <h2 className="gx-font-weight-semi-bold gx-text-white">{headTitle || ''}</h2>
        <h4 className="gx-text-white gx-mb-3">{title || ''}</h4>
        {candidates.map((c) => {
          return <Tooltip key={Math.random(3)} title={c.candidateName}><Badge status={c.status} dot><i className="icon icon-user-o" style={{color: 'white'}} /></Badge></Tooltip>
          })
        }
      </Widget>
    );
  }
};

export default ProspectCard;
