import React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Row, Col, Input, AutoComplete, Icon, Menu} from "antd";
import Auxiliary from "util/Auxiliary";
import IntlMessages from "util/IntlMessages";
import { fetchUserTeams, addTeamMember, onGetTeamById } from "appRedux/actions/Teams";
import { fetchUserContacts } from "appRedux/actions/Contact";
import {onGetUserProfile} from "appRedux/actions/user/Profile";
import UserCard from "components/Widgets/UserCard";

const AutoCompleteOption = AutoComplete.Option;

const RenderCards = (props) => {
  const {teamMembers, menu} = props
  const users = []
  Object.keys(teamMembers).map((key) => {
    const member = {
      id: key,
      ...teamMembers[key]
    }
    users.push(
      <Col key={Math.random()} xl={6} lg={12} md={12} sm={12} xs={24}>
        <UserCard registrant={member} menu={menu} />
      </Col>
    )
  })
  return users
}

class TeamDetails extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      saving: false,
      searchValue: ''
    }
  }

  componentDidMount() {
    const {teamsMetadata,
      contactList, 
      onGetUserProfile, 
      onGetTeamById,
      fetchUserContacts, 
      teamList} = this.props
    const {id} = this.props.match.params
    const callBack = (team) => {}
    if(Object.keys(teamsMetadata).length === 0) onGetUserProfile()
    if(Object.keys(teamsMetadata) && Object.keys(teamList).length === 0 || !teamList[id]) onGetTeamById(id, callBack)
    if(Object.keys(teamsMetadata) && Object.keys(contactList).length === 0) fetchUserContacts()
  }

  menu = (contact) => { 
    return (
        <Menu onClick={() => this.deleteTeamMember(contact)}>
          <Menu.Item key="edit">
            <span>Edit</span>
          </Menu.Item>
          <Menu.Item key="delete">
            <span>Delete</span>
          </Menu.Item>
        </Menu>
      )
    }
  
  resetValues() {
    this.setState({saving: false})
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values)
      }
    });
  }

  deleteTeamMember(contact) {
    console.log('deleteTeamMember for team ----> ', contact)
  }

  onSelect(id) {
    const {addTeamMember} = this.props
    const teamId = this.props.match.params.id
    const callBack = (member) => {
      if(member) this.forceUpdate()      
    }
    const member = this.props.contactList[id]
    addTeamMember(teamId, {[id]: member}, callBack)
  }

  handleContactChange(id) {
    // console.log('handleContactChange', id)
  }

  onChange(e) {
    e.preventDefault()
    const searchValue = e.target.value
    this.setState({searchValue})
  }

  render() {
    const {id} = this.props.match.params
    const {contactList, teamList} = this.props
    const currentTeam = teamList[id] || {}
    const teamMembers = currentTeam.members || {}
    const contactOptions = []
    Object.keys(contactList).map((key) => {
      if(!teamMembers.hasOwnProperty(key)) {
        contactOptions.push(<AutoCompleteOption key={key}>{contactList[key].firstName + ' ' + contactList[key].lastName}</AutoCompleteOption>)
      }
    })
    return (
      <Auxiliary>
        <h2>{currentTeam.name}</h2>
        <div className="gx-ml-auto">
          <span>Add team members: </span>
          <AutoComplete
            style={{width: 250}}
            className="certain-category-search"
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={false}
            dataSource={contactOptions}
            filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.trim().toUpperCase()) !== -1}
            onChange={this.handleContactChange.bind(this)}
            onSelect={this.onSelect.bind(this)}
            placeholder="Search team members"
          >
          <Input suffix={<Icon type="search" className="certain-category-icon"/>}/>
        </AutoComplete>
        </div>
        <Row className="gx-mt-4">
            <RenderCards teamMembers={teamMembers} menu={this.menu} />
        </Row>
      </Auxiliary>
    )
  }
}

function mapStateToProps({user, contact, teams}) {
    const {teamList} = teams
    const {teamsMetadata} = user
    const {contactList} = contact
    return {teamsMetadata, contactList, teamList};
  }
  
  export default connect(
    mapStateToProps,
    {fetchUserTeams,
    fetchUserContacts,
    onGetUserProfile, 
    addTeamMember,
    onGetTeamById})(TeamDetails);