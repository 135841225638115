import React from "react";
import {Avatar} from "antd";
import { Link } from "react-router-dom";
import defaultAvatar from "../../../assets/images/avatar.png"
const ProfileHeader = (props) => {
  const {displayName, location, avatar} = props
  return (
    <div className="gx-profile-banner">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar">
              <Avatar className="gx-size-90" alt={displayName || "..."} src={avatar || defaultAvatar}/>
            </div>
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">{displayName || ''}</h2>
              <p className="gx-mb-0 gx-fs-lg">{location || ''}</p>
            </div>
          </div>
          <div className="gx-profile-banner-top-right">
            {/* <Link to="./updateProfile">
              <span className="gx-link gx-profile-setting">
                <i className="icon icon-setting gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle"/>
                <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">Update Profile</span>
              </span>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProfileHeader;
