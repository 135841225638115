import React from "react";
import {Avatar, Dropdown, Menu, Icon} from "antd";

import Widget from "components/Widget/index";
import { Link } from "react-router-dom";

const menu = (
  <Menu>
    <Menu.Item key="0">Edit</Menu.Item>
  </Menu>
);


const ProjectWidget = ({project, icon, members, menu, link, addAction, btnTitle}) => {
  return (
    <Widget styleName="gx-ch-capitalize gx-card-sm-px" title="">
      <div className="gx-text-center gx-pt-sm-0">
        {/* <img className="gx-size-60 gx-mb-3" src={require("assets/images/widget/birds.png")} alt='birds'/> */}
        <h2 className="gx-mb-3 gx-mb-sm-4">{project.name || "Eagal Hunt App"}</h2>
        <Link to={link}>
          <button className="gx-btn gx-btn-primary gx-text-white gx-mb-1">{btnTitle || 'Go'}</button>
        </Link>
        {menu && <Dropdown overlay={() => menu(project)} trigger={['click']} className="ant-menu-icon">
            <span className="gx-link ant-dropdown-link">
              <Icon type="down"/>
            </span>
          </Dropdown>}
      </div>
    </Widget>
  );
};

export default ProjectWidget;
