export default [
  {
    'title': 'System upgrade',
    'start': new Date(2020, 3, 26, 0, 0, 0),
    'end': new Date(2020, 3, 29, 0, 0, 0)
  },
  {
    'title': 'Meet 1',
    'start': new Date(2020, 3, 27, 15, 0, 0),
    'end': new Date(2020, 3, 27, 15, 30, 0)
  },
  {
    'title': 'Meet 2',
    'start': new Date(2020, 3, 27, 17, 0, 0),
    'end': new Date(2020, 3, 27, 17, 30, 0)
  },
  {
    'title': 'Meet 1',
    'start': new Date(2020, 3, 28, 11, 0, 0),
    'end': new Date(2020, 3, 28, 11, 30, 0)
  },
  {
    'title': 'Meet 2',
    'start': new Date(2020, 3, 28, 10, 0, 0),
    'end': new Date(2020, 3, 28, 10, 30, 0)
  },
  {
    'title': 'Meeting',
    'start': new Date(2020, 3, 29, 10, 30, 0, 0),
    'end': new Date(2020, 3, 29, 11, 30, 0, 0),
    desc: 'Pre-meeting meeting, to prepare for the meeting'
  },
  {
    'title': 'Lunch',
    'start': new Date(2020, 3, 28, 12, 0, 0, 0),
    'end': new Date(2020, 3, 28, 13, 0, 0, 0),
    desc: 'Power lunch'
  },
  {
    'title': 'Meeting',
    'start': new Date(2020, 3, 29, 14, 0, 0, 0),
    'end': new Date(2020, 3, 29, 15, 0, 0, 0)
  },
  {
    'title': 'Happy Hour',
    'start': new Date(2020, 3, 29, 17, 0, 0, 0),
    'end': new Date(2020, 3, 29, 17, 30, 0, 0),
    desc: 'Most important meal of the day'
  },
  {
    'title': 'TEM',
    'start': new Date(2020, 3, 29, 20, 0, 0, 0),
    'end': new Date(2020, 3, 29, 21, 0, 0, 0)
  },
  {
    'title': 'TEM - Training',
    'start': new Date(2020, 3, 29, 21, 0, 0, 0),
    'end': new Date(2020, 3, 29, 22, 0, 0, 0)
  },
  {
    'title': 'Meet 1',
    'start': new Date(2020, 3, 27, 11, 0, 0),
    'end': new Date(2020, 3, 27, 11, 30, 0)
  },
  {
    'title': 'Meet 2',
    'start': new Date(2020, 3, 30, 10, 0, 0),
    'end': new Date(2020, 3, 30, 10, 30, 0)
  },
  {
    'title': 'Followup 1',
    'start': new Date(2020, 3, 27, 15, 0, 0),
    'end': new Date(2020, 3, 27, 15, 30, 0)
  }
]
