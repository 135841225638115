import React, {Component} from "react";
import {connect} from 'react-redux';
import getSummary from "./getSummary"
import RenderDashboard from "./RenderDashboard"
import {onGetUserProfile} from "appRedux/actions/user/Profile";
import {
  onGetAllUserRegistrants,
  filterIbosByCandidateNumber
} from "appRedux/actions/zoom/Meetings";

const INITIAL_STATE = {
  dashboard: {
    totalCandidate: [],
    platinums: [],
    ibos: [],
    platinumIbos: []
  }
}
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    const {profile, roles, defaultSetting, registrants, onGetAllUserRegistrants, onGetUserProfile, authUser} = this.props
    if(!profile.firstName) onGetUserProfile(authUser)
    if(registrants.length == 0 && defaultSetting) onGetAllUserRegistrants(defaultSetting.meetingId)
  }
  filterIBO = (count, dashboard) => {
    this.props.filterIbosByCandidateNumber(dashboard.ibos, count)
  }
  render() {
    let { roles, registrants, filteredIbos, defaultSetting } = this.props
    const dashboard = {
      totalCandidate: [],
      platinums: [],
      ibos: [],
      platinumIbos: []
    }
    if (registrants && registrants.length) getSummary(registrants, dashboard)
    if (filteredIbos && filteredIbos.length === 0) {
      filteredIbos = dashboard.ibos.map(i => i)
    }
    return (
      <div>
        { defaultSetting && defaultSetting.meetingId &&
          <RenderDashboard defaultSetting={defaultSetting} filterIBO={() =>{}} filteredIbos={filteredIbos} dashboard={dashboard} totalCandidate={dashboard.totalCandidate.length} />
        }
        { !defaultSetting || !defaultSetting.meetingId &&
          <h1>Contact portal@aiomeet.com to setup your account.</h1>
        }
      </div>
    );
  }
}

function mapStateToProps({user, meetings, auth}) {
  const {authUser} = auth
  const { registrants, filteredIbos } = meetings
  const {profile, defaultSetting, roles} = user;
  return {profile, roles, defaultSetting, registrants, filteredIbos, authUser};
}

export default connect(
  mapStateToProps,
  {onGetUserProfile,
  onGetAllUserRegistrants,
  filterIbosByCandidateNumber})(Dashboard);