import {
  FETCH_CONTACTS_SUCCESS, 
  GET_USER_CONTACTS,
  ADD_USER_CONTACTS,
  ON_ADD_CONTACT_SUCCESS,
  DELETE_USER_CONTACTS,
  ON_DELETE_CONTACT_SUCCESS} from "../../constants/ActionTypes";

export const fetchUserContactsSuccess = (contacts) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_CONTACTS_SUCCESS,
      payload: contacts
    });
  };
};

export const fetchUserContacts = () => {
  return (dispatch) => {
    dispatch({type: GET_USER_CONTACTS});
  };
};

export const onAddContact = (contact, cb) => {
  return (dispatch) => {
    dispatch({type: ADD_USER_CONTACTS, contact: contact, cb});
  }
};

export const onDeleteContact = (contact, cb) => {
  return (dispatch) => {
    dispatch({type: DELETE_USER_CONTACTS, contact: contact, cb});
  }
};

export const addUserContactSuccess = (contact) => {
  return (dispatch) => {
    dispatch({
      type: ON_ADD_CONTACT_SUCCESS,
      payload: contact
    });
  }
}

export const deleteUserContactSuccess = (contact) => {
  return (dispatch) => {
    dispatch({
      type: ON_DELETE_CONTACT_SUCCESS,
      payload: contact
    });
  }
}
