/**
* Mask email address with asterisks to comply GDPR
* john.doe@example.com => j******e@e****e.com
* @param {string} emailAddress email address
* @returns {string} masked email address
*/
const maskEmailAddress = (emailAddress) => {
  function mask(str) {
    var strLen = str.length;
    if (strLen > 4) {
      return str.substr(0, 1) + str.substr(1, strLen - 1).replace(/\w/g, '*') + str.substr(-1, 1);
    }
    return str.replace(/\w/g, '*');
  }
  return emailAddress.replace(/([\w.]+)@([\w.]+)(\.[\w.]+)/g, function (m, p1, p2, p3) {
    return mask(p1) + '@' + mask(p2) + p3;
  });
}

const maskPhone = (phoneNumber) => {
  const phone = phoneNumber.match(/(\d{3})(\d{3})(\d{4})/)
  return `XXX-XXX-${phone[3]}`
}

module.exports = { maskEmailAddress, maskPhone }