import React from "react";
import {Link} from "react-router-dom";
import {Row, Col, Button, Avatar, Input, Form} from "antd";
import Auxiliary from "util/Auxiliary";
import IntlMessages from "util/IntlMessages";
const FormItem = Form.Item;
class NewTeam extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      saving: false
    }
  }
  
  onSave() {
    alert(this.state.name)
  }
  resetValues() {
    this.props.form.resetFields()
    this.setState({saving: false})
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        Object.keys(values).map(k => {if (!values[k]) delete values[k]})
        this.setState({saving: true})
        this.props.addTeam(values, this.resetValues.bind(this))
      }
    });
  }
  onCancel() {
    alert('cancel')
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showTeamForm} = this.props
    let {saving} = this.state;
    return (
      <Auxiliary>
        <h2><IntlMessages id="contact.addTeam"/></h2>
        <div className="gx-ml-auto">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form onSubmit={this.handleSubmit.bind(this)} layout="inline" className="gx-form-inline-label-up">
              <FormItem className="gx-form-item-two-fourth">
                {getFieldDecorator('name', {
                  rules: [{required: true, message: 'Please input Team Name!', max: 25}],
                })(
                  <Input placeholder="Team Name"/>
                )}
              </FormItem>
              <FormItem>
                <Button 
                disabled={(saving) ? "disabled" : ""}
                type="primary" className="gx-mb-0" htmlType="submit">
                  <span>Add Team</span>
                </Button>
                <Button type="secondary" className="gx-mb-0" onClick={showTeamForm} >
                    <span>Cancel</span>
                </Button>
              </FormItem>
            </Form>
            </Col>
          </Row>
        </div>
      </Auxiliary>
    )
  }
}

const WrappedNewTeam = Form.create()(NewTeam);

export default WrappedNewTeam;
