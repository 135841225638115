import React, {Component} from "react";
import {connect} from 'react-redux';
import {Col, Row} from "antd";
import Selection from "./Selection";
import Auxiliary from "util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {onGetUserProfile} from "appRedux/actions/user/Profile";
import { onGetAllUserMeetings } from "appRedux/actions/zoom/Meetings";

class Meetings extends Component {

  componentDidMount() {
    const {meetings, defaultSetting, onGetAllUserMeetings} = this.props
    if(meetings.length === 0 && defaultSetting.userId) onGetAllUserMeetings(defaultSetting.userId)
  }
  render() {
    const {meetings, loading} = this.props
    return (
      <Auxiliary>
        {loading && <div className="gx-loader-view gx-loader-position ant-modal-mask">
          <CircularProgress/>
        </div>}
        <Row>
          <Col span={24}>
            <Selection meetings={meetings}/>
          </Col>
        </Row>
      </Auxiliary>
    )
  }
}

function mapStateToProps({auth, meetings, user, common}) {
  const {loading} = common
  const {defaultSetting} = user
  const {authUser} = auth
  return {authUser, meetings: meetings.meetings, defaultSetting, loading};
}

export default connect(
  mapStateToProps,
  {onGetUserProfile,
  onGetAllUserMeetings})(Meetings);

