import React from "react";
import Widget from "components/Widget";
import { FaKey, FaUserSecret } from 'react-icons/fa';
import { Input, Form, Button } from "antd";

const FormItem = Form.Item;

const Appconfig = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      console.log("values", values);
      if (!err) {
        props.saveSettings(values);
      }
    });
  }
  const { form } = props
  const { getFieldDecorator } = form
  return (
    <Widget title="Zoom Settings" styleName="gx-card-profile-sm">
      <Form onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
        <FormItem>
          {getFieldDecorator('apikey', {
            initialValue: "",
            rules: [{ required: true, message: 'Please input API Key!' }],
          })(
            <Input prefix={<FaKey />} placeholder="API Key" />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('apisecret', {
            initialValue: "",
            rules: [{ required: true, message: 'Please input API Secret!' }],
          })(
            <Input prefix={<FaUserSecret />} type="password" placeholder="API Secret" />
          )}
        </FormItem>
        {/* <FormItem> */}
          <Button type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{width: '100%'}}>Save</Button>
        {/* </FormItem> */}
      </Form>
    </Widget>
  )
}

const WrappedNormalAppconfig = Form.create()(Appconfig);

export default WrappedNormalAppconfig;
