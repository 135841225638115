import React from "react";
import {connect} from 'react-redux';
import IntlMessages from "util/IntlMessages";
import {Link} from "react-router-dom";
import {Col, Row, Select, Menu, Modal} from "antd";
import UserCard from "components/Widgets/UserCard";
import Auxiliary from "util/Auxiliary";
import hash from "util/createHash"
import {
  fetchUserContacts,
  onAddContact,
  onDeleteContact
} from "appRedux/actions/Contact";
import {
  showAuthLoader
} from "appRedux/actions/Auth";
import WrappedNewContact from "./new"

const Option = Select.Option;
const confirm = Modal.confirm;

const RenderCards = (props) => {
  const {contacts, menu, deleteUser} = props
  const users = []
  Object.keys(contacts).map((key) => {
    users.push(
      <Col key={Math.random()} xl={6} lg={12} md={12} sm={12} xs={24}>
        <UserCard id={key} registrant={contacts[key]} menu={menu} deleteUser={deleteUser}/>
      </Col>
    )
  })
  return users
}

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false
    };
  }
  componentDidMount() {
    const {contactList, fetchUserContacts} = this.props
    if(Object.keys(contactList).length === 0) fetchUserContacts()
  }

  groupOptions() {
    const _iboOptions = []
    for (let i = 0; i<this.state.groups.length; i++) {
      const value = this.state.groups[i]
      _iboOptions.push(
        <Option key={value} value={value}>{value}</Option>
      )
    }
    return _iboOptions
  }

  handleGroupChange(group) {
    alert(`Selected group ${group}`)
  }
  
  rowSelection() {

  }
  showContactForm() {
    this.setState({showForm: !this.state.showForm})
  }

  menu = (contact) => { 
    return (
        <Menu onClick={(e) => this.menuClick(e.key, contact)}>
          <Menu.Item key="edit">
            <span>Edit</span>
          </Menu.Item>
          <Menu.Item key="delete">
            <span className="gx-chart-down">Delete</span>
          </Menu.Item>
        </Menu>
      )
    }
  
  addContact(contact, cb) {
    const callBack = ()  => {
      this.setState({saving: !this.state.saving})
      cb()
    }
    contact.id =  hash.create(contact.email)
    this.props.onAddContact(contact, callBack)
  }

  menuClick(key, contact) {
    const _this = this
    if(key === 'delete') this.showDeleteConfirm(contact, _this) //this.deleteUser(contact)
    if(key === 'edit') console.log('Edit -> ', contact)
  }
  
  deleteUser(contact) {
    const callBack = ()  => {
      this.setState({saving: !this.state.saving})
    }
    if(contact.id) {
      this.props.onDeleteContact(contact, callBack)
    }
  }

  showDeleteConfirm(contact, _this) {
    confirm({
      title: 'Are you sure delete this contact?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        _this.deleteUser(contact)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  render() {
    const {showForm} = this.state
    const {contact} = this.props
    return (
      <Auxiliary>
        <h2>
          <IntlMessages id="chat.contacts"/>
          <a className="gx-ml-3 h5" onClick={() => this.showContactForm()}>
            <i className="icon icon-add"/><span className="gx-pb-3">  Add</span>
          </a>
        </h2>
        <div className="gx-ml-auto">
          {/* <Select className="gx-mb-2 gx-select-sm" defaultValue="By Groups" onChange={(val) => this.handleGroupChange(val)}>
            {this.groupOptions()}
          </Select> */}
          {/* <Select className="gx-mb-2 gx-select-sm gx-ml-3" defaultValue="By all Ibos" onChange={(val) => this.handleIboChange(val)}>
            {this.iboOptions()}
          </Select> */}
          {/* <Button type="primary" className="gx-ml-0 gx-mb-3" size="small" onClick={() => this.getContacts()}>
            <i className="icon icon-anchor gx-mr-2"/> Refresh Data
          </Button> */}
        </div>
        {showForm && <WrappedNewContact addContact={this.addContact.bind(this)} showContactForm={this.showContactForm.bind(this)} />}
        <Row className="gx-mt-3">
          <RenderCards contacts={contact.contactList} menu={this.menu} />
        </Row>
      </Auxiliary>
    )
  }
}

function mapStateToProps({contact}) {
  const {contactList} = contact
  return {contactList, contact};
}

export default connect(
  mapStateToProps,
  {fetchUserContacts, onAddContact, onDeleteContact, showAuthLoader})(Contacts);