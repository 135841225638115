const getSummary = (registrants, dashboard) => {
    const myData = []
    const iboCandidates = {}
    const leaderCandidates = {}
    const iboPlatinum = {}
    const platinumIbos = {}
    const platinumAllIbo = {}
    for (let i in registrants) {
      const rowData = registrants[i]
      if (rowData.custom_questions.length > 4 && rowData.custom_questions[3].value) {
        const leaderName = rowData.custom_questions[0].value
        const iboName = rowData.custom_questions[2].value
        const candidateEmail = rowData.email
        const candidateName = rowData.first_name + ' ' + rowData.last_name
        const iboEmail = rowData.custom_questions[3].value
        const room = rowData.custom_questions[1].value
        let foundCandidate
        if (myData.length) {
          foundCandidate = myData.find((d) => d['Email Address'] === candidateEmail)
        }
        if (candidateEmail !== iboEmail && !foundCandidate) {
          const candidate = {}
          const roomName = `${leaderName} - ${room}`
          candidate['Pre-assign Room Name'] = roomName
          candidate['Email Address'] = candidateEmail
          candidate.candidateName= candidateName
          myData.push(candidate)
          dashboard.totalCandidate.push(candidate)
          const ibo = {}
          ibo['Pre-assign Room Name'] = roomName
          ibo['Email Address'] = iboEmail
          myData.push(ibo)
          iboPlatinum[iboName.toLowerCase()] = leaderName
          if (leaderCandidates[leaderName]) {
            leaderCandidates[leaderName].push(candidate)
          } else {
            leaderCandidates[leaderName] = []
            leaderCandidates[leaderName].push(candidate)
          }
          if(!platinumIbos[leaderName.toLowerCase()]) platinumIbos[leaderName.toLowerCase()] = {}
          platinumIbos[leaderName.toLowerCase()][iboEmail] = true
          if (iboCandidates[iboName.toLowerCase()]) {
            iboCandidates[iboName.toLowerCase()].push(candidate)
          } else {
            iboCandidates[iboName.toLowerCase()] = []
            iboCandidates[iboName.toLowerCase()].push(candidate)
          }
        }
      } else {
        const platinumName = rowData.custom_questions[0].value
        
        if(platinumName){
          if (!platinumAllIbo[platinumName]) platinumAllIbo[platinumName] = []
          const _ibo = {
            candidateName: rowData.first_name + ' ' + rowData.last_name,
            email: rowData.email,
            platinum: platinumName
          }
          platinumAllIbo[platinumName].push(_ibo)
        }
        
      }
    }
    dashboard.platinumIbos = []
    for (let i in platinumAllIbo) {     
      dashboard.platinumIbos.push({
        candidates: platinumAllIbo[i],
        heading: platinumAllIbo[i].length,
        headTitle: platinumAllIbo[i].length + " IBO's",
        title: i.toUpperCase(),
        platinum: i,
        subTitle: "IBO's"
      })
    }
    dashboard.platinumIbos  = dashboard.platinumIbos.sort((a, b) => b.heading - a.heading);
    
    for (let i in iboCandidates) {     
      dashboard.ibos.push({
        candidates: iboCandidates[i],
        heading: iboCandidates[i].length,
        headTitle: iboCandidates[i].length + ' Prospects',
        title: i.toUpperCase(),
        subTitle: 'Candidates',
        platinum: iboPlatinum[i.toLowerCase()],
        ibos: iboPlatinum[i.toLowerCase()]
      })
    }
    dashboard.filteredIbos = dashboard.ibos.sort((a, b) => b.candidates.length - a.candidates.length);
  
    for (let i in leaderCandidates) {
      dashboard.platinums.push({
        heading: leaderCandidates[i].length,
        title: i,
        subTitle: 'Candidates',
        ibos: platinumIbos[i.toLowerCase()]
      })
    }
    dashboard.platinums  = dashboard.platinums.sort((a, b) => b.heading - a.heading);
  }

  export default getSummary