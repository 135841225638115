import React from "react";

import Widget from "components/Widget/index";
import {maskEmailAddress, maskPhone} from "../../util/maskEmail"
import avaratImage from "../../assets/images/avatar.png"
import { Dropdown, Icon, Button} from "antd";

class RegistrantCard extends React.Component {

  render() {
    const { registrant, resend, cb, menu, sideMenu, deleteUser, id} = this.props;
    const { first_name, last_name, email, upline, phone, ibo } = registrant
    let maskedEmail = maskEmailAddress(email)
    let maskedPhone = phone ? maskPhone(phone) : '000-000-0000'
    return (
      <Widget styleName="gx-card-full gx-dot-arrow-hover">
      <div className="gx-user-wid-row">
        {/* <div className="gx-user-wid gx-mr-3">
          <img alt="..." src={avaratImage} className="gx-object-cover"/>
        </div> */}
        <div className="gx-user-wid-body gx-py-3 gx-pr-3 gx-ml-3">
          <div className="ant-row-flex">
            <h2 className="h4 gx-mr-1">{first_name} {last_name}</h2>
          </div>
          <p className="gx-mb-1 gx-text-grey gx-fs-sm">{ibo}</p>
          <p className="gx-mb-1 gx-text-grey gx-fs-sm">{email}</p>
          <p className="gx-mb-1 gx-text-grey gx-fs-sm">{upline}</p>
          <p className="gx-mb-1 gx-text-grey gx-fs-sm">{phone}</p>
          {!resend || <p onClick={() => cb(registrant)} className="gx-mt-3">Re-send Invite</p>}
          {menu && <Dropdown overlay={() => menu(registrant)} id={id} trigger={['click']} className="ant-menu-icon">
            <span className="gx-link ant-dropdown-link">
              <Icon type="down"/>
            </span>
          </Dropdown>}
        </div>
      </div>
    </Widget>
    );
  }
};

export default RegistrantCard;
