import {
  GET_ALL_USER_MEETINGS_SUCCESS,
  UPDATE_REGISTRANTS_SUCCESS,
  FILTER_REGISTRANTS_SUCCESS,
  GET_ALL_MEETINGS_REGISTRANTS_SUCCESS,
  FILTER_IBOS_SUCCESS,
  FAIL_TO_GET_DATA,
} from "../../../constants/ActionTypes";

const INIT_STATE = {
  meetings: [],
  registrants: [],
  renderRegistrants: [],
  platinums: [],
  ibos: [],
  filteredIbos: [],
  callMade: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_MEETINGS_REGISTRANTS_SUCCESS: {
      return {
        ...state,
        registrants: action.payload,
      }
    }
    case GET_ALL_USER_MEETINGS_SUCCESS: {
      return {
        ...state,
        callMade: true,
        meetings: action.payload,
      }
    }
    case UPDATE_REGISTRANTS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case FILTER_REGISTRANTS_SUCCESS: {
      return {
        ...state,
        renderRegistrants: action.payload
      }
    }
    case FILTER_IBOS_SUCCESS: {
      return {
        ...state,
        filteredIbos: action.payload
      }
    }
    case FAIL_TO_GET_DATA: {
      return {
        ...state,
        callMade: true
      }
    }
    default:
      return state;
  }
}
