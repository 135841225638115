import axios from 'axios';
import config from '../util/config'
import { auth } from '../firebase/firebase';
// import { FETCH_ERROR, FAIL_TO_GET_DATA } from "../constants/ActionTypes";

const getHeaders = async () => {
  let { headers } = config
  /**
   * Add token to headers
   */
  // const idToken = await auth.currentUser.getIdToken(true).then(idToken => idToken)
  // headers.Authorization = `Bearer ${idToken}`
  const authUser = localStorage.getItem('user_id')
  return { ...headers, authUser }
}

const getRegistrants = async (meetingId) => {
  const headers = await getHeaders()
  const makeCall = async (meetingId, nextPageToken, resp = {}) => {
    try {
      let url = `${config.serviceEndPoint}/meetings/${meetingId}/registrants?page_size=300`
      if(nextPageToken) url = `${url}&next_page_token=${nextPageToken}`
      return axios.get(url, {headers}).then( response => {
        if(!resp.data) {
          resp = response
        } else {
          resp.data.registrants = [...resp.data.registrants, ...response.data.registrants]
        }
        if (!response.data.next_page_token) return resp
        return makeCall(meetingId, response.data.next_page_token, resp)
      })
    } catch (error) {
      return Promise.reject({ message: "failed to get the registrants" })
    }
  }
  if(meetingId && headers.authUser) {
    const result = await makeCall(meetingId).then(response => response)
    return result
  }
}

const getMeetings = async (userId) => {
  const headers = await getHeaders()
  if(userId && headers.authUser){
    try {
      return await axios.get(`${config.serviceEndPoint}/users/${userId}/meetings?type=upcoming&page_size=300`, {headers})
    } catch (error) {
      return Promise.reject({ message: "failed to get the meetings" })
    }
  }
}

const getMeetingById = async (meetingId) => {
  const headers = await getHeaders()
  if(meetingId && headers.authUser) {
    return await axios.get(`${config.serviceEndPoint}/meetings/${meetingId}`, {headers})
  } 
}

const resendInvite = async (registrant) => {
  console.log(registrant)
}

const generateCsv = async (meetingId) => {
  const headers = await getHeaders()
  if(meetingId && headers.authUser) {
    return await axios.get(`${config.serviceEndPoint}/breakoutRooms/${meetingId}`, {headers})
  }
}

export default { resendInvite, getRegistrants, getMeetings, getMeetingById, generateCsv };