module.exports = {
  footerText: `Copyright aiomeet.com © ${new Date().getFullYear()}`,
  zoomConnect: 'https://zoom.us/oauth/authorize?response_type=code&client_id=mtoiILUNRAOml5_gSiZYWQ&redirect_uri=https%3A%2F%2Finstazoom.us%2Fzoom-redirect',
  serviceEndPoint: 'https://api.instazoom.us',
  headers: {
    'client-secret': 'oWJNMu6wgJi868F6wwUW4zw}hCFdDr3A'
  },
  rawUserData: {
    defaultSetting: {
      meetingId: '',
      userId: ''
    },
    profile: {
      avatar: '',
      city: '',
      displayName: '',
      firstName: '',
      lastName: '',
      zoomEmail: '',
      mobile: '',
      url: '',
      social: [
        { type: 'linkedin', url: '' },
        { type: 'twitter', url: '' },
        { type: 'facebook', url: '' },
        { type: 'instagram', url: '' },
      ]
    }
  }
}
