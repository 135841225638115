import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchUserTeamsSuccess, addUserTeamSuccess, addTeamMemberSuccess, getTeamMembersSuccess } from '../actions/Teams'
import { db, auth } from '../../firebase/firebase';
import { ADD_USER_TEAMS, GET_USER_TEAMS, ADD_TEAM_MEMBER, GET_TEAM } from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import hash from "../../util/createHash"
const authUser = localStorage.getItem('user_id')

const getUserTeams = async (uid) => {
  if (uid) {
    // console.log('CALL: getUserTeams ----------------> ', uid)
    const teamsRef = await db.collection('teams').doc(uid).collection('teams').get()
    return teamsRef.docs.map(doc => {
      return { id: doc.id, ...doc.data() }
    });
  }
}

const getTeamById = async (teamId, uid, cb) => {
  if (uid, teamId) {
    // console.log('CALL: getTeamById ----------------> ', teamId)
    const teamMembersRef = await db.collection('teams').doc(uid).collection('teams').doc(teamId)
    return teamMembersRef.get().then(function (doc) {
      if (doc.exists) {
        const team = { [teamId]: doc.data() }
        cb(team)
        return team
      } else {
        console.log("No such document!")
      }
    }).catch(function (error) {
      console.log("Error getting document:", error)
    });
  }
}

const updateTeamMetadata = async (id, team, uid, cb) => {
  if(id, uid) {
    const userRef = db.collection('users').doc(uid)
    const teamsMetadata = {
      [id]: team
    }
    await userRef.set({ teamsMetadata }, { merge: true }).then().catch(function (error) {
      console.log("Error getting document:", error);
      cb()
    });
  }
}
const addUserTeam = async (team, uid, cb) => {
  if (uid && team && team.name) {
    // console.log('CALL: addUserTeam ----------------> ', team)
    const id = hash.create(team.name)
    const teamsRef = db.collection('teams').doc(uid).collection('teams').doc(id)
    teamsRef.set(team, { merge: true }).then(async () => {
      await updateTeamMetadata(id, team, uid)
      cb()
    }).catch(function (error) {
      console.log("Error getting document:", error);
      cb()
    });
    return { [id]: {...team }}
  }
}

const addTeamMember = async (teamId, member, uid, cb) => {
  if (uid && member) {
    // console.log('CALL: addTeamMember ----------------> ', member, teamId)
    const teamsRef = db.collection('teams').doc(uid).collection('teams').doc(teamId)
    teamsRef.set({ members: { ...member } }, { merge: true }).then(async () => {
      cb(member)
    }).catch(function (error) {
      console.log("Error getting document:", error);
      cb()
    });
    return {id: teamId, member}
  }
}

function* addTeamMemberOnSuccess(payload) {
  const { teamId, member, cb } = payload
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const addedTeamMember = yield call(addTeamMember, teamId, member, uid, cb);
    yield put(addTeamMemberSuccess(addedTeamMember));
  } catch (error) {
    console.log("Error getting document:", error);
    cb()
  }
}

function* getTeamOnSuccess(payload) {
  const { teamId, cb } = payload
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const team = yield call(getTeamById, teamId, uid, cb);
    yield put(getTeamMembersSuccess(team));
  } catch (error) {
    console.log("Error getting document:", error);
    cb()
  }
}

function* addUserTeamOnSuccess(payload) {
  const { team, cb } = payload
  try {
    const uid = authUser || auth.uid
    const addedUserTeam = yield call(addUserTeam, team, uid, cb);
    yield put(addUserTeamSuccess(addedUserTeam));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserTeamsOnSuccess() {
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const fetchedUserTeams = yield call(getUserTeams, uid);
    yield put(fetchUserTeamsSuccess(fetchedUserTeams));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserTeams() {
  yield takeEvery(GET_USER_TEAMS, fetchUserTeamsOnSuccess)
}

export function* addTeam() {
  yield takeEvery(ADD_USER_TEAMS, addUserTeamOnSuccess);
}

export function* addMember() {
  yield takeEvery(ADD_TEAM_MEMBER, addTeamMemberOnSuccess)
}

export function* getTeam() {
  yield takeEvery(GET_TEAM, getTeamOnSuccess)
}

export default function* rootSaga() {
  yield all([fork(addTeam), fork(fetchUserTeams), fork(addMember), fork(getTeam)]);
}
