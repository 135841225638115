import React, {Component} from "react";
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import { Row, Col, Button, Avatar, Input } from "antd";
import Auxiliary from "util/Auxiliary";
import IntlMessages from "util/IntlMessages";
import {onGetUserProfile, onUpdateUserProfile} from "../../appRedux/actions/user/Profile";
import defaultAvatar from "../../assets/images/avatar.png"

class UpdateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: props.profile,
      config: props.config
    };
  }

  componentDidMount() {
    // if(!this.props.profile.firstName) this.props.onGetUserProfile(this.props.authUser)
  }

  onSave() {
    console.log('onSave')
    const {profile, config} = this.state
    this.props.onUpdateUserProfile({profile, config})
  }

  onChange = (e, type) => {
    console.log('onChange')
    if(type === 'profile') {
      const {profile} = this.state
      if(profile[e.target.name] !== e.target.value) {
        profile[e.target.name] = e.target.value
        this.setState({ profile })
      }
    } else {
      const {config} = this.state
      if(config[e.target.name] !== e.target.value) {
        config[e.target.name] = e.target.value
        this.setState({ config })
      }
    }
  };
  render() {
    const { profile, config } = this.state
    const {firstName, lastName, zoomEmail, mobile, avatar} = profile
    const {zoomApiKey, zoomApiSecret} = config
    return (
      <Auxiliary>
        <h2><IntlMessages id="user.myProfile" /></h2>
        <div className="gx-ml-auto">
          <Row className="gx-mb-3">
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <Avatar className="gx-size-90" alt="" src={avatar || defaultAvatar}/>
            </Col>
          </Row>
          <Row>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-form-group">
                <Input
                  name="firstName"
                  required
                  placeholder="First Name"
                  onChange={(event) => this.onChange(event, 'profile')}
                  value={firstName}
                  margin="none" />
              </div>
            </Col >
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-form-group">
                <Input
                  name="lastName"
                  required
                  placeholder="Last Name"
                  onChange={(event) => this.onChange(event, 'profile')}
                  value={lastName}
                  margin="none" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-form-group">
                <Input
                  name="zoomEmail"
                  required
                  placeholder="Zoom Email"
                  onChange={(event) => this.onChange(event, 'profile')}
                  value={zoomEmail}
                  margin="normal" />
              </div>
            </Col >
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-form-group">
                <Input
                  name="mobile"
                  placeholder="Mobile: +1 (222)-333-4444"
                  onChange={(event) => this.onChange(event, 'profile')}
                  value={mobile}
                  margin="normal"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Button>
                <Link to="./myprofile">
                  Cancel
                  </Link>
              </Button>
              <Button type='primary' onClick={() => this.onSave()} >Save</Button>
            </Col>
          </Row>
        </div>
        <h2 className="gx-mt-5"><IntlMessages id="zoom.jwtDetails" /></h2>
        <div className="gx-ml-auto">
          <Row>
            <Col xl={10} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-form-group">
                <Input
                  name="zoomApiKey"
                  required
                  placeholder="API Key"
                  onChange={(event) => this.onChange(event, 'config')}
                  value={zoomApiKey}
                  margin="none" />
              </div>
            </Col >
          </Row>
          <Row>
            <Col xl={10} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-form-group">
                <Input
                  name="zoomApiSecret"
                  required
                  type="password"
                  placeholder="API Secret"
                  onChange={(event) => this.onChange(event, 'config')}
                  value={zoomApiSecret}
                  margin="none" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Button>
                <Link to="./myprofile">
                  Cancel
                  </Link>
              </Button>
              <Button type='primary' onClick={() => this.onSave()} >Save</Button>
            </Col>
          </Row>
        </div>
      </Auxiliary>
    )
  }
}

function mapStateToProps({user, auth}) {
  const {profile, config} = user;
  const {authUser} = auth
  return {profile, config, authUser};
}

export default connect(
  mapStateToProps,
  {onUpdateUserProfile, onGetUserProfile})(UpdateProfile);
