import React, {Component} from "react";
import {connect} from 'react-redux';
import transformReportData from "./transformReportData"
import RenderDashboard from "./RenderDashboard"
import {onGetUserProfile} from "appRedux/actions/user/Profile";
import {
  fetchUserMeetReport
} from "appRedux/actions/Meets";


const INITIAL_STATE = {
  dashboard: {
    totalCandidate: [],
    platinums: [],
    ibos: []
  },
  selectedMeeting: ''
}
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    const {profile, defaultSetting, fetchUserMeetReport, onGetUserProfile, authUser, report} = this.props
    if(!profile.firstName) onGetUserProfile(authUser)
    const {reportMeeting} = defaultSetting
    if(!Object.keys(report).length && reportMeeting && !report[reportMeeting]) fetchUserMeetReport(reportMeeting)
  }

  handleChange = (reportMeeting) => {
    const {report, fetchUserMeetReport} = this.props
    if(reportMeeting && !report[reportMeeting]) {
      this.props.fetchUserMeetReport(reportMeeting)
    }
    this.setState({selectedMeeting: reportMeeting})
  }
  filterIBO = (count, dashboard) => {
    
  }
  render() {
    let {selectedMeeting} = this.state
    let {  filteredIbos, defaultSetting, report } = this.props
    let filteredPlatinums = []
    const dashboard = {
      totalCandidate: [],
      platinums: [],
      ibos: [],
      totalProspects: [],
      divaCandidate: [],
      arTotalCandidate: [],
      arPlatinums: {},
      arIbos: {}
    }
    const meetingIds = []
    if(defaultSetting.reportList) {
      {Object.keys(defaultSetting.reportList).map( item => {
        meetingIds.push(defaultSetting.reportList[item])
      })}
    }
    
    let presentRegistrants = 0
    selectedMeeting = selectedMeeting || Object.keys(report)[0]
    const selectedReport = report[selectedMeeting]
    if(selectedReport) {
      if (Object.keys(report).length) transformReportData(selectedReport['registrants'], dashboard)//getSummary(registrants, dashboard)
      presentRegistrants = dashboard.totalProspects.length //selectedReport.presentRegistrants
    }
    if (filteredIbos && filteredIbos.length === 0) {
      filteredIbos = []
      let ibos = {}
      const foundPlatinums = {}
      for(let i in dashboard.ibos) {
        const ibo = dashboard.ibos[i]
        if(ibo.title) {
          filteredIbos.push(ibo)
        } else {
          ibos = ibo
        }
      }
      if(ibos && ibos.candidates) {
        for(let key in ibos.candidates) {
          const candidate = ibos.candidates[key]
          if(foundPlatinums[candidate.leaderName]) {
            foundPlatinums[candidate.leaderName].push(candidate)
          } else {
            foundPlatinums[candidate.leaderName] = [candidate]
          }
          
        }
        Object.keys(foundPlatinums).map(platinum => {
          const ibos = foundPlatinums[platinum]
          const p = ibos.filter(i => i.status == 'success').length
          const a = ibos.filter(i => i.status == 'error').length
          const headTitle = `T-${ibos.length}, P-${p}, A-${a}`
          if(platinum) {
            filteredPlatinums.push({title: platinum,heading:ibos.length,  headTitle: headTitle,  candidates: ibos})
          }
        })
        filteredPlatinums  = filteredPlatinums.sort((a, b) => b.heading - a.heading);
      }
    }
    const meetTitle = `${selectedReport ? selectedReport.speaker : ''} - ${selectedReport ? selectedReport.day : ''}`
    return (
      <div>
        { defaultSetting && defaultSetting.meetingId &&
          <RenderDashboard handleChange={this.handleChange.bind(this)} selectedMeeting={selectedMeeting} defaultSetting={defaultSetting} meetingIds={meetingIds} meetTitle={meetTitle} filterIBO={() =>{}} filteredIbos={filteredIbos} dashboard={dashboard} totalCandidate={dashboard.divaCandidate.length} presentRegistrants={presentRegistrants} filteredPlatinums={filteredPlatinums} />
        }
        { !defaultSetting || !defaultSetting.meetingId &&
          <h1>Contact portal@aiomeet.com to setup your account.</h1>
        }
      </div>
    );
  }
}

function mapStateToProps({user, meetings, auth, meets}) {
  const {authUser} = auth
  const {report} = meets
  const { registrants, filteredIbos } = meetings
  const {profile, defaultSetting} = user;
  return {profile, defaultSetting, registrants, filteredIbos, authUser, report};
}

export default connect(
  mapStateToProps,
  {onGetUserProfile,
  fetchUserMeetReport})(Report);