const transformReportData = (registrants, dashboard) => {
  const arList = ['Aruna & Thilaka', 'Kanaga & Selvi',
    'Kannan & Dhana', 'Genga & Thevaki', 'Siva & Rani',
    'Lanka & Jeyanthi', 'Simon peter & Polly', 'Selva & Puspa', 'Seelan & Sujitha', 'Kanesh & Kiruba']
  
  const myData = []
  const iboCandidates = {}
  const leaderCandidates = {}
  const prospectList = []
  for (let i in registrants) {
    const registrant = registrants[i]
    // "name": "Michael Chua:michael-chua@hotmail.com:success",
    // "ibo": "Chris Carag:abigailnvf@hotmail.com",
    // "room": "Shivam & Therese:Room 4:kimle173@hotmail.com"
    const candidate = registrant.name.split(':')
    const candidateName = candidate[0].replace('undefined', '')
    const candidateEmail = candidate[1]
    const status = candidate[2]

    const ibo = registrant.ibo ? registrant.ibo.split(':') : []
    const iboName = ibo[0] || ''
    const iboEmail = ibo[1] || ''

    const roomObj = registrant.room.split(':')
    const leaderName = roomObj[0]
    const room = roomObj[1]
    const coach = roomObj[3]
    const prospect = {
      candidateName,
      candidateEmail,
      leaderName,
      iboName,
      iboEmail,
      room,
      coach,
      status
    }

    if(registrant.stats) {
      const stats = registrant.stats.split('_')
      prospect.sessions = stats[0]
      prospect.duration = stats[1]
      prospect.start = stats[2]
      prospect.leave = stats[3]
      prospect.userId = stats[4]
    }

    dashboard.totalCandidate.push(prospect)

    let foundCandidate
    if (myData.length) {
      foundCandidate = myData.find((d) => d.email === candidateEmail)
    }
    if (candidateEmail !== iboEmail && !foundCandidate) {
      // myData.push({ email: iboEmail })
      myData.push({ email: candidateEmail })
      if (!leaderCandidates[leaderName]) leaderCandidates[leaderName] = []
      leaderCandidates[leaderName].push(prospect)

      if (arList.indexOf(leaderName) != -1 && room) {
        if(!dashboard.arPlatinums[leaderName.toLowerCase()]) dashboard.arPlatinums[leaderName.toLowerCase()] = {title: leaderName, candidates: []}
        if(!dashboard.arIbos[iboName.toLowerCase()]) dashboard.arIbos[iboName.toLowerCase()] = {title: iboName, candidates: []}
        
        dashboard.arPlatinums[leaderName.toLowerCase()].candidates.push(prospect)
        dashboard.arIbos[iboName.toLowerCase()].candidates.push(prospect)
        // dashboard.arPlatinums[leaderName] = (leaderName.toLowerCase())
        // dashboard.arIbos.push(iboName.toLowerCase())
        dashboard.arTotalCandidate.push(prospect)
      } 
      if (arList.indexOf(leaderName) == -1 && room) {
        dashboard.divaCandidate.push(prospect)
      }
      if(iboEmail && room) {
        dashboard.totalProspects.push(prospect)
      }

      if (iboCandidates[iboName.toLowerCase()]) {
        iboCandidates[iboName.toLowerCase()].push(prospect)
      } else {
        iboCandidates[iboName.toLowerCase()] = []
        iboCandidates[iboName.toLowerCase()].push(prospect)
      }
    }
  }
  // console.log(dashboard.totalProspects)
  // console.log(dashboard.arPlatinums)
  for (let i in iboCandidates) {
    dashboard.ibos.push({
      candidates: iboCandidates[i],
      heading: iboCandidates[i].length,
      title: i.toUpperCase(),
      leaderName: iboCandidates[i][0].leaderName || '',
      subTitle: 'Prospects'
    })
  }
  dashboard.ibos = dashboard.ibos.sort((a, b) => b.heading - a.heading);

  for (let i in leaderCandidates) {
    // if(i == 'Naresh & Pushpinder') {
    //   console.log(leaderCandidates[i])
    // }
    dashboard.platinums.push({
      heading: leaderCandidates[i].length,
      title: i,
      subTitle: 'Prospects'
    })
  }
  dashboard.platinums  = dashboard.platinums.sort((a, b) => b.heading - a.heading);
}

export default transformReportData