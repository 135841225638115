import React from "react";
import { connect } from 'react-redux';
import IntlMessages from "util/IntlMessages";
import { Col, Row, Select, Menu } from "antd";
import ProjectWidget from "components/Widgets/ProjectWidget";
import Auxiliary from "util/Auxiliary";
import { onGetUserProfile } from "appRedux/actions/user/Profile";
import {
  onAddTeam
} from "appRedux/actions/Teams";
import {
  showAuthLoader
} from "appRedux/actions/Auth";
import WrappedNewTeam from "./new"

const Option = Select.Option;

const RenderCards = (props) => {
  const { teams, menu } = props
  const renderTeams = []
  Object.keys(teams).map((key) => {
    const team = {
      id: key,
      ...teams[key]
    }
    renderTeams.push(
      <Col key={Math.random()} xl={6} lg={8} md={8} sm={12} xs={24}>
        <ProjectWidget btnTitle='Go to Team' project={team} name={teams[key].name} menu={menu} link={`/main/teams/${key}`} />
      </Col>
    )
  })
  return renderTeams
}

class Teams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false
    };
  }

  getTeams() {

  }

  menu = (contact) => {
    return (
      <Menu onClick={() => this.deleteTeam(contact)}>
        <Menu.Item key="edit">
          <span>Edit</span>
        </Menu.Item>
        <Menu.Item key="delete">
          <span>Delete</span>
        </Menu.Item>
      </Menu>
    )
  }

  componentDidMount() {
    const { onGetUserProfile, profile } = this.props
    if (Object.keys(profile).length === 0) onGetUserProfile()
  }

  groupOptions() {
    const _iboOptions = []
    for (let i = 0; i < this.state.groups.length; i++) {
      const value = this.state.groups[i]
      _iboOptions.push(
        <Option key={value} value={value}>{value}</Option>
      )
    }
    return _iboOptions
  }

  handleGroupChange(group) {
    alert(`Selected group ${group}`)
  }

  rowSelection() {

  }
  showTeamForm() {
    this.setState({ showForm: !this.state.showForm })
  }

  addTeam(team, cb) {
    const callBack = () => {
      this.setState({ saving: !this.state.saving })
      cb()
    }
    this.props.onAddTeam(team, callBack)
  }

  deleteTeam(team) {
    console.log('deleteTeam ----> ', team)
  }

  render() {
    const { showForm } = this.state
    const { teamsMetadata } = this.props
    return (
      <Auxiliary>
        <h2>
          <IntlMessages id="sidebar.teams" />
          <a className="gx-ml-3 h5" onClick={() => this.showTeamForm()}>
            <i className="icon icon-add" /><span className="gx-pb-3">  Add</span>
          </a>
        </h2>
        {showForm && <WrappedNewTeam addTeam={this.addTeam.bind(this)} showTeamForm={this.showTeamForm.bind(this)} />}
        <Row className="gx-mt-4">
          <RenderCards teams={teamsMetadata} menu={this.menu} />
        </Row>
      </Auxiliary>
    )
  }
}

function mapStateToProps({ user }) {
  const { profile, teamsMetadata } = user
  return { profile, teamsMetadata };
}

export default connect(
  mapStateToProps,
  { onGetUserProfile, onAddTeam, showAuthLoader })(Teams);