import {
  DELETE_CONTACT_SUCCESS,
  FETCH_CONTACTS_SUCCESS,
  ON_ADD_CONTACT_SUCCESS,
  UPDATE_CONTACT_SUCCESS,
  ON_DELETE_CONTACT_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  contactList: {},
  selectedContact: []
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CONTACTS_SUCCESS: {
      return {
        ...state,
        contactList: action.payload,
      }
    }

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contactList: state.contactList.map((contact) => contact.id === action.payload.id ? action.payload : contact),
      };

    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contactList: state.contactList.filter((contact) => contact.id !== action.payload.id),
      };

    case ON_DELETE_CONTACT_SUCCESS:
      const newContactList = state.contactList
      delete newContactList[action.payload.id]
      return {
        ...state,
        contactList: newContactList,
      };

    case ON_ADD_CONTACT_SUCCESS:
      const newData = state.contactList
      newData[action.payload.id] = action.payload
      return {
        ...state,
        contactList: newData,
      };

    default:
      return state;
  }
}
