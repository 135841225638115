import React, {Component} from "react";
import {connect} from 'react-redux';
import {Col, Row, Button} from "antd";
import Contact from "../../components/profile/Contact/index";
import Appconfig from "../../components/profile/Appconfig/index";
import ZoomCsv from "../../components/profile/ZoomCsv/index";
import Auxiliary from "../../util/Auxiliary";
import ProfileHeader from "../../components/profile/ProfileHeader/index";
import {onGetUserProfile} from "../../appRedux/actions/user/Profile";
import meeting from '../../services/meeting'

class Profile extends Component {
  componentDidMount() {
    if(!this.props.profile.firstName) this.props.onGetUserProfile(this.props.authUser)
  }
  render() {
    const { profile, roles, defaultSetting } = this.props
    const contactList = [
      {
        id: 1,
        title: 'Email',
        icon: 'email',
        desc: [<span className="gx-link" key={1}>{profile.zoomEmail}</span>]
      },
      {
        id: 2,
        title: 'Web page',
        icon: 'link',
        desc: [<span className="gx-link" key={2}>{profile.url}</span>]
      }, {
        id: 3,
        title: 'Mobile',
        icon: 'phone',
        desc: [`${profile.mobile}`]
      },
    ]
    const downloadCsv = (data, filename) => {
      const element = document.createElement("a");
      const file = new Blob([data], {type: 'application/csv'});
      element.href = URL.createObjectURL(file);
      element.download = `${filename || 'breakoutRooms'}.csv`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
    const saveSettings = async (formData) => {
      const meetingId = formData.meetingId
      if(formData && meetingId) {
        const response = await meeting.generateCsv(meetingId)
        const filename = `BR-${meetingId}-${new Date().toISOString()}`
        downloadCsv(response.data, filename)
      } else {
        console.log('no meeting id')
      }
    }
    return (
      <Auxiliary>
        <ProfileHeader displayName={profile.displayName} location={profile.city} avatar={profile.avatar}/>
        <div className="gx-profile-content">
          <Row>
            <Col xl={8} lg={10} md={10} sm={24} xs={24}>
              <Contact contactList={contactList} />
            </Col>
            {(roles.indexOf('dashboard') !== -1) && <Col xl={8} lg={10} md={10} sm={24} xs={24}>
              <ZoomCsv meetingId={defaultSetting.meetingId} saveSettings={saveSettings} />
              <Button type="primary"
                className="login-form-button"
                disabled="disabled"
                style={{width: '100%'}}>Register IBO's</Button>
            </Col>}
            {(roles.indexOf('dashboard') !== -1) && <Col xl={8} lg={10} md={10} sm={24} xs={24}>
              <Appconfig saveSettings={saveSettings} />
            </Col>}
          </Row>
        </div>
      </Auxiliary>
    );
  }
};

function mapStateToProps({user, auth}) {
  const {profile, roles, defaultSetting} = user;
  const {authUser} = auth
  return {profile, roles, authUser, defaultSetting};
}

export default connect(
  mapStateToProps,
  {onGetUserProfile})(Profile);