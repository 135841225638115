// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';

// Zoom
// Meetings
export const GET_ALL_USER_MEETINGS='GET_ALL_USER_MEETINGS'
export const GET_ALL_USER_MEETINGS_SUCCESS='GET_ALL_USER_MEETINGS_SUCCESS'
export const GET_ALL_MEETINGS_REGISTRANTS_SUCCESS='GET_ALL_MEETINGS_REGISTRANTS_SUCCESS'
export const UPDATE_REGISTRANTS='UPDATE_REGISTRANTS'
export const UPDATE_REGISTRANTS_SUCCESS='UPDATE_REGISTRANTS_SUCCESS'
export const FILTER_REGISTRANTS_SUCCESS='FILTER_REGISTRANTS_SUCCESS'
export const FILTER_IBOS_SUCCESS='FILTER_IBOS_SUCCESS'
export const FAIL_TO_GET_DATA='FAIL_TO_GET_DATA'

// Users
export const FAIL_TO_UPDATE_DATA='FAIL_TO_UPDATE_DATA'
export const UPDATE_USER_CONFIG_SUCCESS='UPDATE_USER_CONFIG_SUCCESS'
export const UPDATE_USER_PROFILE_SUCCESS='UPDATE_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_SUCCESS='GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE='GET_USER_PROFILE'
export const UPDATE_USER_PROFILE='UPDATE_USER_PROFILE'
export const ON_ADD_USER_TEAM_SUCCESS='ON_ADD_USER_TEAM_SUCCESS'

// Contacts
export const FETCH_CONTACTS_SUCCESS="FETCH_CONTACTS_SUCCESS"
export const GET_USER_CONTACTS="GET_USER_CONTACTS"
export const ADD_USER_CONTACTS="ADD_USER_CONTACTS"
export const ADD_CONTACTS_SUCCESS="ADD_CONTACTS_SUCCESS"
export const DELETE_USER_CONTACTS="DELETE_USER_CONTACTS"
export const ON_DELETE_CONTACT_SUCCESS="ON_DELETE_CONTACT_SUCCESS"

// Teams
export const FETCH_TEAMS_SUCCESS="FETCH_TEAMS_SUCCESS"
export const GET_USER_TEAMS="GET_USER_TEAMS"
export const ADD_USER_TEAMS="ADD_USER_TEAMS"
export const ON_ADD_TEAM_SUCCESS="ON_ADD_TEAM_SUCCESS"
export const DELETE_TEAM_SUCCESS="DELETE_TEAM_SUCCESS"
export const UPDATE_TEAM_SUCCESS="UPDATE_TEAM_SUCCESS"
export const ADD_TEAM_MEMBER="ADD_TEAM_MEMBER"
export const ON_ADD_TEAM_MEMBER_SUCCESS="ON_ADD_TEAM_MEMBER_SUCCESS"
export const GET_TEAM="GET_TEAM"
export const ON_GET_TEAM_SUCCESS="ON_GET_TEAM_SUCCESS"

// Meets
export const FETCH_MEETS_SUCCESS="FETCH_MEETS_SUCCESS"
export const GET_USER_MEETS="GET_USER_MEETS"
export const ADD_USER_MEETS="ADD_USER_MEETS"
export const ON_ADD_USER_MEET_SUCCESS="ON_ADD_USER_MEET_SUCCESS"
export const ON_ADD_MEET_SUCCESS="ON_ADD_MEET_SUCCESS"
export const ADD_MEET_MEMBER="ADD_MEET_MEMBER"
export const ON_ADD_MEET_MEMBER_SUCCESS="ON_ADD_MEET_MEMBER_SUCCESS"
export const DELETE_MEET_SUCCESS="DELETE_MEET_SUCCESS"
export const UPDATE_MEET_SUCCESS="UPDATE_MEET_SUCCESS"
export const GET_MEET="GET_MEET"
export const ON_GET_MEET_SUCCESS="ON_GET_TEAM_SUCCESS"
export const FETCH_MEET_REPORT_SUCCESS="FETCH_MEET_REPORT_SUCCESS"
export const FETCH_MEET_REPORT="FETCH_MEET_REPORT"
export const GET_USER_MEET_REPORT="GET_USER_MEET_REPORT"