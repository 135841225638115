import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchUserMeetsSuccess,
  addUserMeetSuccess,
  addMeetMemberSuccess,
  getMeetMembersSuccess,
  fetchUserMeetReportSuccess} from '../actions/Meets'
import { db, auth } from '../../firebase/firebase';
import { ADD_USER_MEETS, GET_USER_MEETS, ADD_MEET_MEMBER, GET_MEET, FETCH_MEET_REPORT } from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import hash from "../../util/createHash"
const authUser = localStorage.getItem('user_id')

const getUserMeets = async (uid) => {
  if (uid) {
    // console.log('CALL: getUserMeets ----------------> ', uid)
    const meetsRef = await db.collection('meets').doc(uid).collection('meets').get()
    return meetsRef.docs.map(doc => {
      return { id: doc.id, ...doc.data() }
    });
  }
}

const getUserMeetReport = async (uid, reportMeeting) => {
  if (uid && reportMeeting) {
    // console.log('CALL: getUserMeetReport ----------------> ', uid)
    const meetReportRef = await db.collection('meetReports').doc(uid).collection('meet').doc(reportMeeting)
    // return meetReportRef.docs.map(doc => {
    //   return { id: doc.id, ...doc.data() }
    // });
    return meetReportRef.get().then(function (doc) {
      if (doc.exists) {
        const report =  doc.data()
        // console.log('report------------>', report)
        let reportObj = {}
        reportObj[reportMeeting] = report
        return reportObj
      } else {
        console.log("No such document!")
      }
    }).catch(function (error) {
      console.log("Error getting document:", error)
    });
  }
}

const getMeetById = async (meetId, uid, cb) => {
  if (uid, meetId) {
    // console.log('CALL: getMeetById ----------------> ', meetId)
    const meetMembersRef = await db.collection('meets').doc(uid).collection('meets').doc(meetId)
    return meetMembersRef.get().then(function (doc) {
      if (doc.exists) {
        const meet = { [meetId]: doc.data() }
        cb(meet)
        return meet
      } else {
        console.log("No such document!")
      }
    }).catch(function (error) {
      console.log("Error getting document:", error)
    });
  }
}

const updateMeetsMetadata = async (id, meet, uid, cb) => {
  if(id, uid) {
    const userRef = db.collection('users').doc(uid)
    const meetsMetadata = {
      [id]: meet
    }
    await userRef.set({ meetsMetadata }, { merge: true }).then().catch(function (error) {
      console.log("Error getting document:", error);
      cb()
    });
  }
}
const addUserMeet = async (meet, uid, cb) => {
  if (uid && meet && meet.name) {
    // console.log('CALL: addUserMeet ----------------> ', meet)
    const id = hash.create(meet.name)
    const meetsRef = db.collection('meets').doc(uid).collection('meets').doc(id)
    meetsRef.set(meet, { merge: true }).then(async () => {
      await updateMeetsMetadata(id, meet, uid)
      cb()
    }).catch(function (error) {
      console.log("Error getting document:", error);
      cb()
    });
    return { [id]: {...meet }}
  }
}

const addMeetMember = async (meetId, member, uid, cb) => {
  if (uid && member) {
    // console.log('CALL: addMeetMember ----------------> ', member, meetId)
    const meetsRef = db.collection('meets').doc(uid).collection('meets').doc(meetId)
    meetsRef.set({ members: { ...member } }, { merge: true }).then(async () => {
      cb(member)
    }).catch(function (error) {
      console.log("Error getting document:", error);
      cb()
    });
    return {id: meetId, member}
  }
}

function* addMeetMemberOnSuccess(payload) {
  const { meetId, member, cb } = payload
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const addedTeamMember = yield call(addMeetMember, meetId, member, uid, cb);
    yield put(addMeetMemberSuccess(addedTeamMember));
  } catch (error) {
    console.log("Error getting document:", error);
    cb()
  }
}

function* getMeetOnSuccess(payload) {
  const { meetId, cb } = payload
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const meet = yield call(getMeetById, meetId, uid, cb);
    yield put(getMeetMembersSuccess(meet));
  } catch (error) {
    console.log("Error getting document:", error);
    cb()
  }
}

function* addUserMeetOnSuccess(payload) {
  const { meet, cb } = payload
  try {
    const uid = authUser || auth.uid
    const addedUserMeet = yield call(addUserMeet, meet, uid, cb);
    yield put(addUserMeetSuccess(addedUserMeet));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserMeetsOnSuccess() {
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const fetchedUserTeams = yield call(getUserMeets, uid);
    yield put(fetchUserMeetsSuccess(fetchedUserTeams));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserMeetReportOnSuccess(payload) {
  
  const {reportMeeting} = payload
  // console.log('-----> reportMeeting',reportMeeting)
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const fetchedUserMeetReport = yield call(getUserMeetReport, uid, reportMeeting);
    yield put(fetchUserMeetReportSuccess(fetchedUserMeetReport));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserMeets() {
  yield takeEvery(GET_USER_MEETS, fetchUserMeetsOnSuccess)
}

function* fetchUserMeetReport() {
  yield takeEvery(FETCH_MEET_REPORT, fetchUserMeetReportOnSuccess)
}

export function* addMeet() {
  yield takeEvery(ADD_USER_MEETS, addUserMeetOnSuccess);
}

export function* addMember() {
  yield takeEvery(ADD_MEET_MEMBER, addMeetMemberOnSuccess)
}

export function* getMeet() {
  yield takeEvery(GET_MEET, getMeetOnSuccess)
}

export default function* rootSaga() {
  yield all([fork(addMeet),
    fork(fetchUserMeets),
    fork(addMember),
    fork(getMeet),
    fork(fetchUserMeetReport)
  ]);
}
