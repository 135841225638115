import React from "react";
import Widget from "components/Widget";
import { FaKey, FaUserSecret } from 'react-icons/fa';
import { Input, Form, Button } from "antd";

const FormItem = Form.Item;

const Appconfig = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.saveSettings(values);
      }
    });
  }
  
  const { form, meetingId } = props
  const { getFieldDecorator } = form
  return (
    <Widget title="Breakout Rooms" styleName="gx-card-profile-sm">
      <Form onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
        <FormItem>
          {getFieldDecorator('meetingId', {
            initialValue: meetingId,
            rules: [{ required: true, message: 'Please input Meeting ID!' }],
          })(
            <Input prefix={<FaKey />}  name="meetingId" id="meetingId" placeholder="Meeting ID" />
          )}
        </FormItem>
        {/* <FormItem> */}
          <Button type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{width: '100%'}}>Download CSV</Button>
        {/* </FormItem> */}
      </Form>
    </Widget>
  )
}

const WrappedNormalAppconfig = Form.create()(Appconfig);

export default WrappedNormalAppconfig;
