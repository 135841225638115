import React from "react";
import moment from 'moment'
import { Col, Row, Table, Badge, Tooltip, Slider, Select, Tabs } from "antd";
import Auxiliary from "util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import { useSelector } from "react-redux";

const Option = Select.Option;
const TabPane = Tabs.TabPane;

const RenderDashboard = (props) => {
  const { selectedMeeting, totalCandidate, presentRegistrants, filteredIbos, filteredPlatinums, dashboard, meetTitle, meetingIds, handleChange } = props
  const { platinums, arPlatinums, arIbos, arTotalCandidate } = dashboard
  const loading = useSelector(({ common }) => common.loading);
  const marks = {
    0: '',
    12: {
      style: {
        color: '#f50',
      },
      label: <span>8:00 PM</span>,
    },
    62: {
      style: {
        color: '#f50',
      },
      label: <span>9:00 PM</span>,
    },
    100: ''
  };
  let arPresentProspect = 0
  arTotalCandidate.map(c => {if (c.status === 'success') {arPresentProspect++} })
  let arPlatinumsNumber = Object.keys(arPlatinums).length
  let arIbosNumber = Object.keys(arIbos).length
  const arList = ['Aruna & Thilaka', 'Kanaga & Selvi',
    'Kannan & Dhana', 'Genga & Thevaki', 'Siva & Rani',
    'Lanka & Jeyanthi', 'Simon Peter & Polly', 'Selva & Puspa', 'Seelan & Sujitha', 'Kanesh & Kiruba']
    function compare( a, b ) {
      if ( a.status > b.status ){
        return -1;
      }
      if ( a.status < b.status ){
        return 1;
      }
      return 0;
    }
  for(let key in filteredPlatinums) {
    filteredPlatinums[key].candidates = filteredPlatinums[key].candidates.sort(compare) //.sort((a, b) => b.status - a.status)
  }

  const expandedRowRender = record => {
    const candidates = []
    record.candidates = record.candidates.sort(compare)
    for (let key in record.candidates) {
      const candidate = record.candidates[key]
      candidate.entryTimeColor = '#52c41a'
      candidate.leaveTimeColor = '#52c41a'
      let start = 0
      let leave = 0
      let _start = ''
      let _leave = ''
      if (candidate.start) {
        _start = new Date(candidate.start)
        _leave = new Date(candidate.leave)
        let eventStart = _start.toLocaleString().split(',')
        eventStart[1] = "19:45:00"
        eventStart = new Date(eventStart.join(', ')).getTime()
        let eventEnd = _start.toLocaleString().split(',')
        eventEnd[1] = "21:45:00"
        eventEnd = new Date(eventEnd.join(', ')).getTime()
        const entryDiff = _start.getTime() - eventStart
        const leaveDiff = eventEnd - _leave.getTime()
        
        if(entryDiff > 1800000) candidate.entryTimeColor = '#faad14'
        if(entryDiff > 2700000) candidate.entryTimeColor = '#ff4d4f'
        if(leaveDiff > 3600000) candidate.leaveTimeColor = '#faad14'
        if(leaveDiff > 4500000) candidate.leaveTimeColor = '#ff4d4f'

        start = Math.round((_start.getTime() - eventStart) / 72000)
        leave = Math.round(100 - ((eventEnd - _leave.getTime()) / 72000))
      }
      
      const {candidateName, entryTimeColor, leaveTimeColor} = candidate
      // if(record.candidates[key].iboName == '') {
        let recordTime = []
        // if(eventStart) console.log(eventStart, _start)
        // console.log('timeColor ----->', timeColor)
        candidates.push(
          <Row key={Math.random(3)}>
            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
              {candidateName}
              <br />
              {_start ? <Badge style={{ backgroundColor: entryTimeColor }} count={moment(_start).format('LT')} /> : ''}
              {_start ? <Badge style={{ backgroundColor: leaveTimeColor }} count={moment(_leave).format('LT')} /> : ''}
              {/* {_start ? `${moment(_start).format('LT')} - ${moment(_leave).format('LT')}` : '__:__ - __:__'} */}
            </Col>
            <Col xl={18} lg={18} md={18} sm={18} xs={18}>
              <Slider tipFormatter={(value, obj) => {
                
                if(recordTime.indexOf(candidateName) == -1) {
                  recordTime.push(candidateName)
                  return `${moment(_start).format('LT')}`
                } else {
                  recordTime = []
                  return `${moment(_leave).format('LT')}`
                }
              }
              } range defaultValue={start ? [start, leave] : []}
                marks={marks}
                disabled={true} />
            </Col>
          </Row>)
        // candidates.push(<p style={{margin: 0}}>{record.candidates[key].candidateName} {record.candidates[key].start ? ` - Joined at ${start.toLocaleString()} and Left at ${leave.toLocaleString()}` : ''}</p>)
        }
      // }
      
    return candidates
  };
  const columns = [{
    title: 'IBO Name',
    dataIndex: 'title',
    width: 200,
    render: text => <span>{text}</span>,
  }, {
    title: 'Prospects',
    dataIndex: 'candidates',
    render: (candidates) => {
      const badges = []
      badges.push(<Badge key={Math.random(3)} style={{ backgroundColor: '#038fde' }} count={candidates.length} />)
      candidates.map((c) => {
        badges.push(<Tooltip key={Math.random(3)} title={c.candidateName}><Badge key={Math.random(3)} status={c.status} dot><i className="icon icon-user-o" style={{ color: 'grey' }} /></Badge></Tooltip>)
      })
      return badges
    },
  }];

  const platnumColumns = [{
    title: 'Platinum Name',
    dataIndex: 'title',
    width: 200,
    render: text => <span>{text}</span>,
  }, {
    title: 'IBOs',
    dataIndex: 'candidates',
    render: (candidates, ibo) => {
      // console.log(a, '-----')
      const badges = []
      badges.push(<Badge key={Math.random(3)} style={{ backgroundColor: '#038fde' }} count={ibo.headTitle} />)
      badges.push(<br key={Math.random(3)} />)
      candidates.map((c) => {
        badges.push(<Tooltip key={Math.random(3)} title={c.candidateName}><Badge key={Math.random(3)} status={c.status} dot><i className="icon icon-user-o" style={{ color: 'grey' }} /></Badge></Tooltip>)
      })
      return badges
    },
  }];

  const meetingOptions = []
  meetingIds.forEach(id => meetingOptions.push(<Option key={id} value={id}>{id}</Option>))

  const titleBadges = []
  titleBadges.push(
    <Select key={Math.random(3)} className="gx-mr-3 gx-mb-3" defaultValue={selectedMeeting || "Select Meeting Id"} style={{ width: 120 }} onChange={(e) => handleChange(e)}>
      {meetingOptions}
    </Select>
  )
  titleBadges.push(
    <span key={Math.random(3)} className="gx-mr-3">{meetTitle}</span>
  )
  titleBadges.push(
    <Badge key={Math.random(3)} count={`Prospects ${presentRegistrants - arPresentProspect}/${totalCandidate}`} style={{ backgroundColor: '#52c41a' }} />
  )
  titleBadges.push(
    <Badge key={Math.random(3)} count={`IBO's ${filteredIbos.length - arIbosNumber}`} style={{ backgroundColor: '#52c41a' }} />
  )
  titleBadges.push(
    <Badge key={Math.random(3)} count={`Platinums & Up ${platinums.length - arPlatinumsNumber}`} />
  )
  const arTitleBadges = []
  arTitleBadges.push(
    <span key={Math.random(3)} className="gx-mr-3">{meetTitle}</span>
  )
  arTitleBadges.push(
    <Badge key={Math.random(3)} count={`Prospects ${arPresentProspect}/${arTotalCandidate.length}`} style={{ backgroundColor: '#52c41a' }} />
  )
  arTitleBadges.push(
    <Badge key={Math.random(3)} count={`IBO's ${Object.keys(arIbos).length}`} style={{ backgroundColor: '#52c41a' }} />
  )
  arTitleBadges.push(
    <Badge key={Math.random(3)} count={`Platinums & Up ${arPlatinumsNumber}`} />
  )
  // filteredIbos.filter(ibo => console.log(ibo))
  const divaIbos = []
  for(let key in filteredIbos) {
    // console.log(filteredIbos[key].leaderName)
    if(arList.indexOf(filteredIbos[key].leaderName) == -1) divaIbos.push(filteredIbos[key])
  }
  const divasPlatinums = []
  const arunasPlatinums = []
  for(let key in filteredPlatinums) {
    if (arList.indexOf(filteredPlatinums[key].title) == -1) {
      divasPlatinums.push(filteredPlatinums[key])
    } else {
      arunasPlatinums.push(filteredPlatinums[key])
    }
  }
  // console.log(divaIbos)
  return (
    <Auxiliary>
      {loading && <div className="gx-loader-view gx-loader-position ant-modal-mask">
        <CircularProgress />
      </div>}
      
      <Tabs defaultActiveKey="1">
        <TabPane tab="Diva Team" key="1">
          {titleBadges}
          {/* <Row>
            {
              filteredPlatinums.map(item => {
                // console.log(item)
                if (arList.indexOf(item.title) == -1) {
                  return <Col key={Math.random(3)} xl={6} lg={12} md={12} sm={12} xs={24}>
                    <ProspectCard key={Math.random(3)} data={item} themeStyle={'gx-blue-cyan-gradient' || 'gx-pink-purple-gradient'} />
                  </Col>
                }
                }
              )
            }
          </Row> */}
          <Row className="gx-mb-3">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Table rowKey={record => Math.random()}
                className="gx-table-responsive"
                columns={platnumColumns}
                dataSource={divasPlatinums}
                scroll={{ y: 550 }}
                expandedRowRender={expandedRowRender}
                // pagination={{pageSize: 150}}
                pagination={false}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Table rowKey={record => Math.random()}
                className="gx-table-responsive"
                columns={columns}
                dataSource={divaIbos}
                scroll={{ y: 550 }}
                expandedRowRender={expandedRowRender}
                // pagination={{pageSize: 150}}
                pagination={false}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Aruna Team" key="2">
          {arTitleBadges}
          {/* <Row>
            {
              filteredPlatinums.map(item => {
                if (arList.indexOf(item.title) != -1) {
                  return <Col key={Math.random(3)} xl={6} lg={12} md={12} sm={12} xs={24}>
                    <ProspectCard key={Math.random(3)} data={item} themeStyle={'gx-pink-purple-gradient'} />
                  </Col>
                }
                }
              )
            }
          </Row> */}
          <Row className="gx-mb-3">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Table rowKey={record => Math.random()}
                className="gx-table-responsive"
                columns={platnumColumns}
                dataSource={arunasPlatinums}
                scroll={{ y: 550 }}
                expandedRowRender={expandedRowRender}
                // pagination={{pageSize: 150}}
                pagination={false}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Table rowKey={record => Math.random()}
                className="gx-table-responsive"
                columns={columns}
                dataSource={Object.keys(arIbos).map(key => arIbos[key])}
                scroll={{ y: 550 }}
                expandedRowRender={expandedRowRender}
                // pagination={{pageSize: 150}}
                pagination={false}
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </Auxiliary>
  )
}

export default RenderDashboard
