import {
  DELETE_TEAM_SUCCESS,
  FETCH_TEAMS_SUCCESS,
  ON_ADD_TEAM_SUCCESS,
  UPDATE_TEAM_SUCCESS,
  ON_GET_TEAM_SUCCESS,
  ON_ADD_TEAM_MEMBER_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  teamList: {},
  selectedTeam: []
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TEAMS_SUCCESS: {
      return {
        ...state,
        teamList: action.payload,
      }
    }

    case UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        teamList: state.teamList.map((team) => team.id === action.payload.id ? action.payload : team),
      };

    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        teamList: state.teamList.filter((team) => team.id !== action.payload.id),
      };

    case ON_ADD_TEAM_SUCCESS:
      const newData = {...state.teamList, ...action.payload}
      return {
        ...state,
        teamList: newData,
      };
    case ON_GET_TEAM_SUCCESS:
      const team = action.payload.team
      return{
        ...state,
        teamList: {...state.teamList, ...team}
      }
    case ON_ADD_TEAM_MEMBER_SUCCESS:
      const {id, member} = action.payload
      const teamList = state.teamList
      teamList[id]['members'] = {
        ...teamList[id]['members'],
        ...member
      }

      return {
        ...state,
        teamList
      }
    default:
      return state;
  }
}
