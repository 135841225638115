import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  GET_ALL_USER_MEETINGS_SUCCESS,
  GET_ALL_MEETINGS_REGISTRANTS_SUCCESS,
  UPDATE_REGISTRANTS_SUCCESS,
  FILTER_IBOS_SUCCESS,
  FILTER_REGISTRANTS_SUCCESS,
  FAIL_TO_GET_DATA
} from "../../../constants/ActionTypes";
import meeting from '../../../services/meeting'
import transformMeeting from '../../../util/transformMeeting'

export const onGetAllUserRegistrants = (meetingId) => {
  return (dispatch) => {
    const authUser = localStorage.getItem('user_id')
    dispatch({ type: FETCH_START });
    if (meetingId && authUser) {
      meeting.getRegistrants(meetingId)
        .then(resp => {
          dispatch({ type: FETCH_SUCCESS });
          const { registrants } = resp.data
          dispatch({
            type: GET_ALL_MEETINGS_REGISTRANTS_SUCCESS,
            payload: registrants
          });
        }).catch(err => {
          console.log(err)
          dispatch({ type: FETCH_ERROR });
          dispatch({ type: FAIL_TO_GET_DATA });
        })
    }
    dispatch({ type: FETCH_ERROR });
    dispatch({ type: FAIL_TO_GET_DATA });
  };
};

export const onGetAllUserMeetings = (userId) => {
  return (dispatch) => {
    const authUser = localStorage.getItem('user_id')
    dispatch({ type: FETCH_START });
    if (authUser && userId) {
      meeting.getMeetings(userId)
        .then(resp => {
          dispatch({ type: FETCH_SUCCESS });
          const { meetings } = resp.data
          dispatch({
            type: GET_ALL_USER_MEETINGS_SUCCESS,
            payload: meetings
          });
        }).catch(err => {
          console.log(err)
          dispatch({ type: FETCH_ERROR });
          dispatch({ type: FAIL_TO_GET_DATA });
        })
    }
    dispatch({ type: FETCH_ERROR });
    dispatch({ type: FAIL_TO_GET_DATA });
  };
};

export const updateRegistrants = (allRegistrants) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    const platinums = []
    const ibos = []
    const renderRegistrants = transformMeeting.transformRegistrant(allRegistrants, platinums, ibos)
    dispatch({ type: FETCH_SUCCESS });
    dispatch({
      type: UPDATE_REGISTRANTS_SUCCESS,
      payload: { renderRegistrants, platinums, ibos }
    });
  };
}

export const filterRegistrants = (allRegistrants, key, filterBy) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    const registrants = allRegistrants.filter(r => r[key] === filterBy)
    dispatch({ type: FETCH_SUCCESS });
    dispatch({
      type: FILTER_REGISTRANTS_SUCCESS,
      payload: registrants
    });
  };
}

export const filterIbosByCandidateNumber = (ibos, count) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    const filteredIbos = ibos.filter(r => r.heading >= count)
    dispatch({ type: FETCH_SUCCESS });
    dispatch({
      type: FILTER_IBOS_SUCCESS,
      payload: filteredIbos
    });
  };
}

export const dataFeched = () => {
  return (dispatch) => { dispatch({ type: FAIL_TO_GET_DATA }); }
}