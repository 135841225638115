import React from "react";
import {Button} from "antd";

import Widget from "components/Widget/index";

class FlyingBird extends React.Component {

  render() {
    const {appName, appSubtitle, appLogo, href} = this.props;
    return (
      <Widget>
        <div className="gx-media gx-align-items-center gx-mb-4">
          <div className="gx-mr-3">
            <img src={require(`assets/images/appLogos/${appLogo || 'flying'}.png`)} width="50" alt='flying'/>
          </div>
          <div className="gx-media-body">
            <h2 className="gx-mb-1">
              {appName || 'Flying bird'}
            </h2>
            <p className="gx-text-grey gx-mb-0">{appSubtitle || 'Bob Bush'}</p>
          </div>
        </div>
        <p className="gx-mb-4">Connect the app to start using the supported features.</p>
        <Button 
          disabled={!href ? 'disabled':''}
          type="primary" icon="plus" 
          href={href || ''}
          className="gx-mb-1" htmlType="submit">
          Connect
        </Button>
        
        <Button disabled={href ? 'disabled':''} type="danger" icon="minus" className="gx-mb-1" htmlType="submit">
          Disconnect
        </Button>
      </Widget>
    );
  }
};

export default FlyingBird;
