import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchProfileSuccess } from '../actions/user/Profile';
import { fetchUserContactsSuccess } from '../actions/Contact'
import {onGetAllUserRegistrants} from "../actions/zoom/Meetings"
import { db, auth } from '../../firebase/firebase';
import { GET_USER_PROFILE, UPDATE_USER_PROFILE, SIGNIN_USER_SUCCESS, GET_USER_CONTACTS } from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
const authUser = localStorage.getItem('user_id')

const getUserContacts = async (uid) => {
  if (uid) {
    const userRef = db.collection('contacts').doc(uid).collection('list').doc('contacts')
    return userRef.get().then(function (doc) {
      if (doc.exists) {
        return doc.data()
      } else {
        console.log("No such document!");
      }
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }
}

const getUserProfile = async (uid) => {
  if(!uid) uid = auth.uid || localStorage.getItem('user_id')
  if (uid) {
    const userRef = db.collection("users").doc(uid)
    const userSub = db.collection("userSubscription").doc(uid)
    let sub = {}
    await userSub.get().then(function (doc) {
      if (doc.exists) {
        sub = doc.data()
      } else {
        sub.plan = 'basic'
      }
    })
    const subscription = db.collection("subscriptions").doc(sub.plan)
    let access = {}
    await subscription.get().then(function (doc) {
      access = doc.data()
    })
    return userRef.get().then(function (doc) {
      if (doc.exists) {
        return {...doc.data(), ...access}
      } else {
        console.log("No such document!");
      }
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }
}

const updateUserProfile = async ({user}) => {
  if (auth.currentUser && user) {
    const userRef = db.collection("users").doc(auth.currentUser.uid)
    return await userRef.set(user).then(function () {
      console.log("User updated");
      return user
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }
}

function* fetchUserRequest() {
  try {
    const uid = authUser || auth.uid || authUser
    const fetchedUser = yield call(getUserProfile, uid);
    if(fetchedUser && fetchedUser.defaultSetting && fetchedUser.defaultSetting.meetingId){
      yield put(onGetAllUserRegistrants(fetchedUser.defaultSetting.meetingId))
    }
    yield put(fetchProfileSuccess(fetchedUser));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserRequestOnSuccess() {
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const fetchedUser = yield call(getUserProfile, uid);
    if(fetchedUser && fetchedUser.defaultSetting && fetchedUser.defaultSetting.meetingId){
      yield put(onGetAllUserRegistrants(fetchedUser.defaultSetting.meetingId))
    }
    yield put(fetchProfileSuccess(fetchedUser));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserContactsOnSuccess() {
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const fetchedUserContacts = yield call(getUserContacts, uid);
    yield put(fetchUserContactsSuccess(fetchedUserContacts));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserContacts() {
  yield takeEvery(GET_USER_CONTACTS, fetchUserContactsOnSuccess)
}

export function* fetchUser() {
  yield takeEvery(GET_USER_PROFILE, fetchUserRequest);
}

export function* fetchUserOnSuccess() {
  yield takeEvery(SIGNIN_USER_SUCCESS, fetchUserRequestOnSuccess);
}

export function* updateUser() {
  yield takeEvery(UPDATE_USER_PROFILE, updateUserProfile);
}

export default function* rootSaga() {
  yield all([fork(fetchUser), fork(updateUser), fork(fetchUserOnSuccess), fork(fetchUserContacts)]);
}
