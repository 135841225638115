import React from "react";
import {connect} from 'react-redux';
import IntlMessages from "util/IntlMessages";
import {Col, Row, Select, Menu} from "antd";
import ProjectWidget from "components/Widgets/ProjectWidget";
import Auxiliary from "util/Auxiliary";
import {onGetUserProfile} from "appRedux/actions/user/Profile";
import {
  onAddMeet
} from "appRedux/actions/Meets";
import {
  showAuthLoader
} from "appRedux/actions/Auth";
import WrappedNewTeam from "./new"

const Option = Select.Option;

const RenderCards = (props) => {
  const {meets, menu} = props
  const renderMeet = []
  Object.keys(meets).map((key) => {
    const meet = {
      id: key,
      ...meets[key]
    }
    renderMeet.push(
      <Col key={Math.random()} xl={6} lg={8} md={8} sm={12} xs={24}>
        <ProjectWidget btnTitle='Go to Meet' menu={menu} project={meet} link={`/main/meets/${key}`} />
      </Col>
    )
  })
  return renderMeet
}

class Meets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false
    };
  }

  getMeet() {

  }

  menu = (contact) => { 
    return (
        <Menu onClick={() => this.deleteMeet(contact)}>
          <Menu.Item key="edit">
            <span>Edit</span>
          </Menu.Item>
          <Menu.Item key="delete">
            <span>Delete</span>
          </Menu.Item>
        </Menu>
      )
    }

  componentDidMount() {
    const {onGetUserProfile, profile} = this.props
    if(Object.keys(profile).length === 0) onGetUserProfile()
  }

  deleteMeet(meet) {
    console.log('deleteMeet ----> ', meet)
  }

  groupOptions() {
    const _iboOptions = []
    for (let i = 0; i<this.state.groups.length; i++) {
      const value = this.state.groups[i]
      _iboOptions.push(
        <Option key={value} value={value}>{value}</Option>
      )
    }
    return _iboOptions
  }

  handleGroupChange(group) {
    alert(`Selected group ${group}`)
  }
  
  rowSelection() {

  }
  showTeamForm() {
    this.setState({showForm: !this.state.showForm})
  }
  
  addMeet(meet, cb) {
    const callBack = ()  => {
      this.setState({saving: !this.state.saving})
      cb()
    }
    this.props.onAddMeet(meet, callBack)
  }

  render() {
    const {showForm} = this.state
    const {meetsMetadata} = this.props
    return (
      <Auxiliary>
        <h2>
          <IntlMessages id="sidebar.meets"/>
          <a className="gx-ml-3 h5" onClick={() => this.showTeamForm()}>
            <i className="icon icon-add"/><span className="gx-pb-3">  Add</span>
          </a>
        </h2>
        {showForm && <WrappedNewTeam addMeet={this.addMeet.bind(this)} showTeamForm={this.showTeamForm.bind(this)}/>}
        <Row className="gx-mt-4">
          <RenderCards meets={meetsMetadata} menu={this.menu} />
        </Row>
      </Auxiliary>
    )
  }
}

function mapStateToProps({user}) {
  const {profile, meetsMetadata} = user
  return {profile, meetsMetadata};
}

export default connect(
  mapStateToProps,
  {onGetUserProfile, onAddMeet, showAuthLoader})(Meets);