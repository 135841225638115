import React from "react";
import {Badge} from "antd";
import { Link } from "react-router-dom";
import Widget from "components/Widget/index";
import { Dropdown, Icon} from "antd";

export default class BuildingCard extends React.Component {
  render() {
    const {badge, title, link, menu, team, id} = this.props
    return (
      <Widget styleName="gx-card-full gx-dot-arrow-hover">
        <div className="gx-media gx-align-items-center gx-flex-nowrap">
          <div className="gx-px-3 gx-build-box-lay">
            <img alt="..." src={require('assets/images/widget/building.png')} className=""/>
          </div>
          <div className="gx-media-body gx-py-3 gx-pr-4 gx-build-box-lay-content">

            <Badge className="gx-badge-radius-sm gx-mb-2" count={badge} style={{backgroundColor: '#52c41a'}}/>
            <h2 className="h4 gx-text-truncate gx-mb-1">{title || 'Total Prospects'}</h2>

            <p className="gx-mb-0 gx-text-grey gx-fs-sm">Team Evaluation Meeting</p>
            <div className="gx-dot-arrow">
              <div className="gx-bg-primary gx-hover-arrow">
                <Link to={link}>
                  <i className="icon icon-long-arrow-right gx-text-white"/>
                </Link>
              </div>
              <div className="gx-dot">
                <i className="icon icon-ellipse-v gx-text-primary"/>
              </div>
            </div>
          </div>
          {menu && <Dropdown overlay={() => menu(team)} id={id} trigger={['click']} className="ant-menu-icon">
            <span className="gx-link ant-dropdown-link">
              <Icon type="down"/>
            </span>
          </Dropdown>}
        </div>
      </Widget>
    );
  }
}
