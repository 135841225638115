import React from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import {useDispatch} from "react-redux";
import {userSignOut} from "appRedux/actions/Auth";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {useSelector} from "react-redux";
import defaultAvatar from "../../assets/images/avatar.png"

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const SidebarContent = () => {

  let {navStyle, themeType, pathname} = useSelector(({settings}) => settings);
  let {profile, defaultSetting, roles} = useSelector(({user}) => user);
  
  const dispatch = useDispatch();
  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <>
      <SidebarLogo/>
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile avatar={profile.avatar || defaultAvatar}/>
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <MenuItemGroup key="main" className="gx-menu-group" title={<IntlMessages id="sidebar.main"/>}>

            {(roles.indexOf('dashboard') != -1) && <Menu.Item key="main/dashboard">
                <Link to="/main/dashboard"><i className="icon icon-dasbhoard"/>
                  <span><IntlMessages id="sidebar.dashboard"/></span></Link>
              </Menu.Item>}
              {(roles.indexOf('calendar') != -1) && <Menu.Item key="main/calendar">
                <Link to="/main/calendar"><i className="icon icon-calendar-new"/>
                  <span><IntlMessages id="sidebar.calendar"/></span></Link>
              </Menu.Item>}
              
              {(roles.indexOf('contacts') != -1) && <Menu.Item key="main/contacts">
                <Link to="/main/contacts"><i className="icon icon-contacts"/>
                  <span><IntlMessages id="sidebar.contacts"/></span></Link>
              </Menu.Item>}

              {(roles.indexOf('teams') != -1) && <Menu.Item key="main/teams">
                <Link to="/main/teams"><i className="icon icon-team"/>
                  <span><IntlMessages id="sidebar.teams"/></span></Link>
              </Menu.Item>}

              {(roles.indexOf('meets') != -1) && <Menu.Item key="main/meets">
                <Link to="/main/meets"><i className="icon icon-schedule"/>
                  <span><IntlMessages id="sidebar.meets"/></span></Link>
              </Menu.Item>}

              { defaultSetting && defaultSetting.userId && (roles.indexOf('meetings') != -1) &&
                <Menu.Item key="main/meetings">
                  <Link to="/main/meetings"><i className="icon icon-calendar"/>
                    <span><IntlMessages id="sidebar.meetings"/></span></Link>
                </Menu.Item>
              }

              {(roles.indexOf('reports') != -1) && <Menu.Item key="main/reports">
                <Link to="/main/reports"><i className="icon icon-timeline"/>
                  <span><IntlMessages id="sidebar.reports"/></span></Link>
              </Menu.Item>}
              {/* <Menu.Item key="main/attendees">
                <Link to="/main/meetings/attendees"><i className="icon icon-team"/>
                  <span><IntlMessages id="sidebar.attendees"/></span></Link>
              </Menu.Item> */}


              {/* <SubMenu key="settings" className={getNavStyleSubMenuClass(navStyle)}
                       title={<span> <i className="icon icon-setting"/>
                         <span><IntlMessages id="sidebar.settings"/></span></span>}> */}
                {/* <Menu.Item key="settings/connect">
                  <Link to="/settings/connect">
                    <i className="icon icon-apps"/>
                    <span><IntlMessages id="sidebar.settings.connect"/></span>
                  </Link>
                </Menu.Item> */}
                {/* <Menu.Item key="settings/email">
                  <Link to="/settings/email">
                    <i className="icon icon-email"/>
                    <span><IntlMessages id="sidebar.settings.email"/></span>
                  </Link>
                </Menu.Item> */}
              {/* </SubMenu> */}

              <Menu.Item key="main/signout">
                <Link to="#" onClick={() => dispatch(userSignOut())}><i className="icon icon-signin"/>
                  <span><IntlMessages id="sidebar.signout"/></span></Link>
              </Menu.Item>
            </MenuItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

